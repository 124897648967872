import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {Box, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import DropboxIcon from 'assets/DropboxIcon.svg';
import cx from 'classnames';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {TeamMembersAndSubTeamsCounts} from 'components/shared/TeamMembersAndSubTeamsCounts';
import {
  Team,
  TeamsTreeItem,
  useTeamsCountSuspense,
  useTeamsTreeSuspense,
} from 'components/teams/hooks';
import {TeamBody} from 'components/teams/TeamBody';
import {TeamsTree} from 'components/teams/TeamsTree';
import {t} from 'i18next';
import {Suspense, useMemo, useState} from 'react';

import styles from './TeamsView.module.css';

function findTeamPath(teams: TeamsTreeItem[], teamId: string, path: number[] = []): number[] {
  for (let idx = 0; idx < teams.length; idx++) {
    const team = teams[idx];
    if (team.teamId === teamId) {
      return [...path, idx];
    }
    if (team.subTeams) {
      const result = findTeamPath(team.subTeams, teamId, [...path, idx]);
      if (result?.length) {
        return result;
      }
    }
  }
  return [];
}

function flattenTree(tree: TeamsTreeItem[]) {
  let result: TeamsTreeItem[] = [];

  tree.forEach((node) => {
    result.push(node);
    if (node.subTeams) {
      result = result.concat(flattenTree(node.subTeams));
    }
  });

  return result;
}

const TeamNav = ({
  team,
  search,
  setSearch,
}: {
  team: Team;
  search: string;
  setSearch: (search: string) => void;
}) => {
  const teams = useTeamsTreeSuspense();
  const flattenedTree = useMemo(() => flattenTree(teams), [teams]);
  const selectedTeamPath = useMemo(() => findTeamPath(teams, team.teamId), [teams, team]);

  return (
    <>
      {search.length ? (
        <div>
          {flattenedTree
            .filter(({teamName}) => teamName.toLowerCase().includes(search.toLowerCase()))
            .map(({teamName, teamId, slug}) => (
              <Link
                to={`/teams/${slug}`}
                key={teamId}
                onClick={() => setSearch('')}
                className={cx(styles.teamsTreeItemContainer, styles.teamsTreeSearchItem)}
                hasNoUnderline
              >
                <Text size="small" variant="paragraph" isBold>
                  {teamName}
                </Text>
              </Link>
            ))
            .slice(0, 20)}
        </div>
      ) : (
        <TeamsTree teams={teams} selectedIndicies={selectedTeamPath} />
      )}
    </>
  );
};

const TotalTeamCounts = () => {
  const {employees, teams} = useTeamsCountSuspense();

  return <TeamMembersAndSubTeamsCounts membersCount={employees} subTeamsCount={teams} />;
};

export const TeamsView = ({team}: {team: Team}) => {
  const [search, setSearch] = useState<string>('');

  return (
    <Layout.Container>
      <Layout.Nav>
        <Title size={24}>{t('teams')}</Title>
        <Box
          as={Link}
          to="/teams"
          hasNoUnderline
          display="flex"
          padding="8"
          borderRadius="Medium"
          alignItems="center"
          marginTop="24"
          marginBottom="8"
          onClick={() => setSearch('')}
          {...withShade({state: 'animated'})}
        >
          <img src={DropboxIcon} width="64" />
          <Box marginLeft="16">
            <Text size="large" tagName="div" isBold>
              Dropbox
            </Text>
            <Suspense fallback={<Skeleton.Text />}>
              <TotalTeamCounts />
            </Suspense>
          </Box>
        </Box>
        <TextInput
          placeholder={t('select_teams').toString()}
          withLeftAccessory={<UIIcon src={SearchLine} />}
          value={search}
          size="medium"
          isTransparent
          onChange={(e) => setSearch(e.target.value)}
        />
        <Suspense
          fallback={
            <Box display="flex" flexDirection="row" alignItems="center" padding="4" margin="4">
              <Skeleton.Avatar size="small" />
              <Box marginLeft="8" width="100%">
                <Skeleton.Text width={200} />
              </Box>
            </Box>
          }
        >
          <TeamNav team={team} search={search} setSearch={setSearch} />
        </Suspense>
      </Layout.Nav>
      <Layout.Body>
        <TeamBody team={team} />
      </Layout.Body>
    </Layout.Container>
  );
};
