import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/typography';
import {Box, useTheme} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {isMobileAtom} from 'atoms/layout';
import {Link} from 'components/DSYS/Link';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Trans} from 'react-i18next';
import {DASHBOARD_STATUSES, DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';
import {DashboardsInfoMessage} from 'views/dashboards/DashboardsInfoMessage';
import {createTeamGoalsPath, getStatusColorOverride, GoalCountsData} from 'views/dashboards/util';

export const DashboardsStatusSelectorWithInfoMessage = ({
  type,
  timeframe,
  teamName,
  teamSlug,
  statusSelectorRef,
  selectedStatus,
  setSelectedStatus,
  totalGoalCountsData,
  statusesOverride,
}: DashboardsStatusSelectorProps & {
  type: string;
  timeframe: string;
  teamName: string;
  teamSlug: string;
  totalGoalCountsData: GoalCountsData | undefined;
  statusesOverride?: string[];
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <Box
      display="flex"
      alignItems={isMobile ? 'flex-start' : 'center'}
      flexDirection={isMobile ? 'column' : 'row'}
      style={{gap: '16px'}}
    >
      <DashboardsStatusSelector
        statusSelectorRef={statusSelectorRef}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        source="heatmap"
        statusesOverride={statusesOverride}
      />
      <Box display="flex" alignItems="center">
        {totalGoalCountsData && (
          <>
            <DashboardsInfoMessage
              message={
                <Trans
                  i18nKey="team_heatmap_info_message"
                  t={t}
                  values={{
                    perc: totalGoalCountsData.percentageCeil,
                    team: teamName,
                    status: t(selectedStatus).toLowerCase(),
                  }}
                  components={{
                    Text: <Text size="small" isBold />,
                  }}
                />
              }
            />
            {teamSlug &&
              teamSlug !== DASHBOARDS_DROPBOX_TEAM_SLUG &&
              !!totalGoalCountsData.statusCount && (
                <Box marginLeft="6">
                  <Link
                    to={createTeamGoalsPath(teamSlug, selectedStatus, timeframe)}
                    onClick={() => {
                      analyticsLogger().logEvent('DASHBOARD_VIEW_ALL_CLICKED', {
                        type,
                        status: selectedStatus,
                      });
                    }}
                    target="_blank"
                  >
                    <Text size="small" isBold>
                      {t('view_all')}
                    </Text>
                  </Link>
                </Box>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

type DashboardsStatusSelectorProps = {
  statusSelectorRef: React.RefObject<HTMLDivElement>;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  source: string;
  statusesOverride?: string[];
};

export const DashboardsStatusSelector = ({
  statusSelectorRef,
  selectedStatus,
  setSelectedStatus,
  source,
  statusesOverride,
}: DashboardsStatusSelectorProps) => {
  const {mode} = useTheme();

  const statuses = statusesOverride ?? DASHBOARD_STATUSES;
  return (
    <Menu.Wrapper onSelection={setSelectedStatus}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button
            {...getTriggerProps()}
            variant="outline"
            size="large"
            fullWidth
            withDropdownIcon
            withIconStart={
              <StatusButtonIcon
                status={selectedStatus}
                statusColorOverride={getStatusColorOverride(selectedStatus, mode)}
              />
            }
          >
            <Box as={Text} textAlign="left" style={{width: '230px'}} ref={statusSelectorRef}>
              {t(selectedStatus)}
            </Box>
          </Button>
          <Menu.Content {...getContentProps()} style={{width: '290px'}}>
            <Menu.Segment>
              {statuses.map((status) => (
                <Menu.SelectItem
                  key={`dashboards-status-option__${source}__${status}`}
                  value={status}
                  selected={selectedStatus === status}
                  withLeftAccessory={
                    <StatusButtonIcon
                      status={status}
                      statusColorOverride={getStatusColorOverride(status, mode)}
                    />
                  }
                >
                  {t(status)}
                </Menu.SelectItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
