import {useQuery} from '@tanstack/react-query';
import {DashboardService, Employee, GoalData} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export type DashboardsGoalCounts = {
  team_slug: string;
  total_counts: {[status: string]: number};
  members_counts: {[ldap: string]: {[status: string]: number}};
  subteams_counts?: {[slug: string]: {[status: string]: number}};
  is_total_counts_loading?: boolean;
  is_subteams_counts_loading?: boolean;
};

const useTotalGoalCountsQuery = ({teamSlug, timeframe}: {teamSlug: string; timeframe: string}) => {
  return useQuery({
    queryKey: ['dashboardsTotalGoalCounts', teamSlug, timeframe],
    queryFn: () =>
      getService(DashboardService).totalGoalCountsApiV1DashboardsTotalGoalCountsGet(
        teamSlug,
        timeframe
      ),
  });
};

const useSubteamGoalCountsQuery = ({
  teamSlug,
  timeframe,
  enabled = false,
}: {
  teamSlug: string;
  timeframe: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['dashboardsSubteamGoalCounts', teamSlug, timeframe],
    queryFn: () =>
      getService(DashboardService).subteamGoalCountsApiV1DashboardsSubteamGoalCountsGet(
        teamSlug,
        timeframe
      ),
    enabled,
  });
};

const useMemberGoalCountsQuery = ({
  teamSlug,
  timeframe,
  enabled = false,
}: {
  teamSlug: string;
  timeframe: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['dashboardsMemberGoalCounts', teamSlug, timeframe],
    queryFn: () =>
      getService(DashboardService).memberGoalCountsApiV1DashboardsMemberGoalCountsGet(
        teamSlug,
        timeframe
      ),
    enabled,
  });
};

export const useDashboardsGoalCounts = ({
  teamSlug,
  timeframe,
  includeMembersCounts,
}: {
  teamSlug: string;
  timeframe: string;
  includeMembersCounts: boolean;
}): DashboardsGoalCounts => {
  const {data: totalGoalCountsData, isLoading: isTotalCountsLoading} = useTotalGoalCountsQuery({
    teamSlug,
    timeframe,
  });

  const {data: subteamGoalCountsData, isLoading: isSubteamsCountsLoading} =
    useSubteamGoalCountsQuery({
      teamSlug,
      timeframe,
      enabled: true,
    });
  const subteamsCounts: {[slug: string]: {[status: string]: number}} =
    subteamGoalCountsData?.subteams_counts ?? {};
  Object.keys(subteamsCounts).forEach((subteamSlug) => {
    queryClient.setQueryData(['dashboardsTotalGoalCounts', subteamSlug, timeframe], {
      team_slug: subteamSlug,
      total_counts: subteamsCounts[subteamSlug],
    });
  });

  const {data: memberGoalCountsData} = useMemberGoalCountsQuery({
    teamSlug,
    timeframe,
    enabled: includeMembersCounts,
  });

  return {
    team_slug: teamSlug,
    total_counts: totalGoalCountsData?.total_counts,
    members_counts: includeMembersCounts ? memberGoalCountsData?.members_counts : {},
    subteams_counts: subteamsCounts,
    is_total_counts_loading: isTotalCountsLoading,
    is_subteams_counts_loading: isSubteamsCountsLoading,
  };
};

export const usePeopleWidget = ({
  type,
  teamSlug,
  timeframe,
  limit,
  enabled,
}: {
  type: string;
  teamSlug: string;
  timeframe: string;
  limit: number;
  enabled: boolean;
}) => {
  const {data, isLoading, isFetched} = useQuery({
    queryKey: ['dashboardsPeopleWidget', type, teamSlug, timeframe, limit],
    queryFn: () =>
      getService(DashboardService).peopleWidgetApiV1DashboardsPeopleWidgetGet(
        type,
        teamSlug,
        timeframe,
        limit
      ),
    enabled,
  });

  const people: Employee[] = data?.people ?? [];
  const remainingCount = data?.remaining_count ?? 0;
  const totalPeopleCount = data?.total_people_count ?? 0;
  const totalEmployeesCount = data?.total_employees_count ?? 0;
  return {isLoading, isFetched, people, remainingCount, totalPeopleCount, totalEmployeesCount};
};

export const useGoalsWidget = ({
  type,
  teamSlug,
  timeframe,
  statuses,
  enabled,
}: {
  type: string;
  teamSlug: string;
  timeframe: string;
  statuses: string[];
  enabled: boolean;
}) => {
  const statusesString = statuses.join(',');
  const {data, isLoading, isFetched} = useQuery({
    queryKey: ['dashboardsGoalsWidget', type, teamSlug, timeframe, statusesString],
    queryFn: () =>
      getService(DashboardService).goalsWidgetApiV1DashboardsGoalsWidgetGet(
        type,
        teamSlug,
        timeframe,
        statusesString
      ),
    enabled,
  });

  const goalsByStatus: {[status: string]: GoalData[] | null} = data?.goals_by_status ?? {};
  const totalGoalsCount = data?.total_goals ?? 0;
  return {isLoading, isFetched, goalsByStatus, totalGoalsCount};
};
