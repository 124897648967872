import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Drawer} from '@dropbox/dig-components/dist/drawer';
import {Dropzone} from '@dropbox/dig-components/dist/dropzone';
import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {List} from '@dropbox/dig-components/dist/list';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Select, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddLine,
  ArrowLeftLine,
  CheckmarkLine,
  CloseLine,
  DeleteLine,
  EditLine,
  FailLine,
  NewsLine,
  ShowLine,
  UploadLine,
} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {snackbarAtom} from 'atoms/snackbar';
import {NewsCategory, NewsCategoryUpdate, NewsCategoryWithCount} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useNewsCategoryUpdate} from 'views/news/hooks';

import styles from './NewsModify.module.css';
import {NewsPostPreview} from './NewsPostPreview';
import {NewsThumbnail} from './NewsThumbnail';

export const NewsModifyHeader = ({isEditing}: {isEditing?: boolean}) => {
  const navigate = useNavigate();

  return (
    <Split alignY="center" paddingBottom="16" gap="6">
      <Split.Item>
        <IconButton variant="borderless" className={styles.backButton} onClick={() => navigate(-1)}>
          <UIIcon src={ArrowLeftLine} size="medium" />
        </IconButton>
      </Split.Item>
      <Split.Item paddingTop="4">
        <Box as={UIIcon} src={NewsLine} color="Text Subtle" />
      </Split.Item>
      <Split.Item width="fill">
        <Text size="xlarge" isBold>
          {isEditing ? t('edit_post') : t('new_post')}
        </Text>
      </Split.Item>
    </Split>
  );
};

export const NewsPreviewDrawer = ({
  isOpen,
  setDrawerIsOpen,
  title,
  category,
  body,
  onDrawerClose,
  onDrawerOpen,
}: {
  isOpen: boolean;
  setDrawerIsOpen: (isOpen: boolean) => void;
  title: string;
  category: string[];
  body: string;
  onDrawerClose: () => void;
  onDrawerOpen: () => void;
}) => {
  useEffect(() => {
    // Add a listener to keyboard esc key to close the live preview drawer
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) {
        return;
      }
      if (event.key === 'Escape') {
        setDrawerIsOpen(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, setDrawerIsOpen]);

  return (
    <Drawer.InlineDrawer
      style={{
        ...(isOpen && {overflow: 'scroll', position: 'fixed', marginTop: '52px'}),
      }}
      isOpen={isOpen}
      width="728px"
      alignment="right"
      onWillClose={onDrawerClose}
      onWillOpen={onDrawerOpen}
    >
      <Drawer.Container>
        <Drawer.Header>
          <Drawer.Layout>
            <Drawer.LayoutItem shift="left" width="fill">
              <Split alignY="center">
                <Split.Item>
                  <UIIcon src={ShowLine} style={{marginTop: '4px', marginRight: '4px'}} />
                </Split.Item>
                <Split.Item>
                  <Box as={Eyebrow} color="Text Subtle" size="xsmall">
                    {t('preview')}
                  </Box>
                </Split.Item>
              </Split>
            </Drawer.LayoutItem>
            <Drawer.LayoutItem shift="right">
              <IconButton variant="borderless" onClick={() => setDrawerIsOpen(false)}>
                <UIIcon src={CloseLine} />
              </IconButton>
            </Drawer.LayoutItem>
          </Drawer.Layout>
        </Drawer.Header>
        <Drawer.Body>
          <NewsPostPreview title={title} category={category} body={body} />
        </Drawer.Body>
      </Drawer.Container>
    </Drawer.InlineDrawer>
  );
};

export const NewsModifyTitle = ({
  title,
  setTitle,
}: {
  title: string;
  setTitle: (title: string) => void;
}) => (
  <FormRow>
    <FormLabel
      className={styles.formTitle}
      withInput={
        <TextInput
          size="large"
          placeholder={t('start_writing_placeholder')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      }
    >
      {t('title')}
    </FormLabel>
  </FormRow>
);

export const CategorySearchMenu = ({
  possibleCategories,
  selectedCategories,
  onSelectCategory,
  onRemoveCategory,
  id,
  setCategory,
}: {
  possibleCategories: NewsCategoryWithCount[];
  selectedCategories: string[];
  onSelectCategory: (category: NewsCategory) => void;
  onRemoveCategory: (category: string) => void;
  id?: number;
  setCategory?: (category: NewsCategory[]) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const close = () => setOpen(false);

  const inputContainerRef = useRef<HTMLElement | null>(null);
  const [userInputValue, setUserInputValue] = useState<string>('');
  const searchPool = possibleCategories.reduce((acc, cat) => {
    acc.push(cat.name);
    return acc;
  }, [] as string[]);
  const searchResult = searchPool.filter(
    (cat) =>
      cat.toLowerCase().startsWith(userInputValue.toLowerCase()) &&
      !selectedCategories.includes(cat)
  );

  const categoryHints = searchResult.length ? searchResult : [];

  const handleSelection = (category: string) => {
    const found = categoryHints.find((c) => c === category);
    const newsCategoryObj = possibleCategories.filter((item) => item.name === found);
    if (found) {
      onSelectCategory(newsCategoryObj[0]);
      setUserInputValue('');
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const renderTypeaheadRow = (category: string) => {
    return <Typeahead.Row key={category} value={category} withTitle={category} />;
  };

  return (
    <>
      <FormRow>
        <Split>
          <Split.Item width="fill">
            <FormLabel>{t('category')}</FormLabel>
          </Split.Item>
          <Split.Item>
            <Button variant="transparent" size="small" onClick={() => setOpen(true)}>
              {t('manage_categories')}
            </Button>
          </Split.Item>
        </Split>
        <Typeahead.Wrapper onSelection={handleSelection}>
          {({getTriggerProps, getContentProps}) => (
            <>
              <TextInput.Container ref={inputContainerRef}>
                <TextInput.ChipsContainer>
                  {!!selectedCategories.length &&
                    selectedCategories.map((category, i) => (
                      <Chip key={i} onDelete={() => onRemoveCategory(category)} size="small">
                        <Chip.Content>{category}</Chip.Content>
                      </Chip>
                    ))}
                  <TextInput.Input
                    {...getTriggerProps({onChange: onInputChange})}
                    placeholder={t('select_placeholder')}
                    value={userInputValue}
                  />
                </TextInput.ChipsContainer>
              </TextInput.Container>
              <Typeahead.Container {...getContentProps()} triggerRef={inputContainerRef}>
                <Typeahead.Results
                  size="large"
                  results={searchResult}
                  renderRow={renderTypeaheadRow}
                  initialResults={searchResult.length}
                />
              </Typeahead.Container>
            </>
          )}
        </Typeahead.Wrapper>
      </FormRow>
      <ManageCategory
        possibleCategories={possibleCategories}
        open={open}
        close={close}
        id={id}
        selectedCategories={selectedCategories}
        setCategory={setCategory}
      />
    </>
  );
};

export const ManageCategory = ({
  possibleCategories,
  open,
  close,
  id,
  selectedCategories,
  setCategory,
}: {
  possibleCategories: NewsCategoryWithCount[];
  open: boolean;
  close: () => void;
  id?: number;
  selectedCategories?: string[];
  setCategory?: (category: NewsCategory[]) => void;
}) => {
  const {updateNewsCategory} = useNewsCategoryUpdate(id);
  return (
    <ManageCategoryModal
      possibleCategories={possibleCategories}
      open={open}
      close={close}
      onSubmit={({data}) => updateNewsCategory({data})}
      selectedCategories={selectedCategories}
      setCategory={setCategory}
    />
  );
};

export const ManageCategoryModal = ({
  possibleCategories,
  open,
  close,
  onSubmit,
  selectedCategories,
  setCategory,
}: {
  possibleCategories: NewsCategoryWithCount[];
  open: boolean;
  close: () => void;
  onSubmit: (data: {data: NewsCategoryUpdate[]}) => Promise<NewsCategory[]>;
  selectedCategories?: string[];
  setCategory?: (category: NewsCategory[]) => void;
}) => {
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const [categories, setCategories] = useState<NewsCategoryWithCount[]>(
    possibleCategories.map((possibleCategory) => ({
      name: possibleCategory.name,
      id: possibleCategory.id,
      count: possibleCategory.count,
    }))
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<string>('');

  const handleCategoryChanges = async () => {
    const response = await onSubmit({
      data: categories.map((category) => ({
        id: category.id,
        name: category.name,
      })),
    });

    if (!response) {
      setSnackbarMessage({text: t('couldnt_save')});
    } else {
      setSnackbarMessage({text: t('saved')});
    }

    if (selectedCategories && setCategory) {
      const newCategories = selectedCategories.reduce((acc, selectedCategory) => {
        const found = categories.find((category) => category.name === selectedCategory);
        if (found) {
          acc.push(found);
        }
        return acc;
      }, [] as NewsCategory[]);
      setCategory(newCategories);
    }
  };

  const handleDelete = (category: string) => {
    if (isEditing === category) {
      setIsEditing('');
      setInputValue('');
    }
    setCategories(categories.filter((existingCategory) => existingCategory.name !== category));
  };

  const handleEdit = useCallback(() => {
    if (categories.find((category) => category.name === inputValue.trim())) {
      setIsInvalid(true);
    } else {
      setCategories(
        categories.map((existingCategory) =>
          existingCategory.name === isEditing
            ? {
                id: existingCategory.id,
                name: inputValue.trim(),
                count: existingCategory.count,
              }
            : existingCategory
        )
      );
    }
    setInputValue('');
    setIsEditing('');
  }, [categories, inputValue, isEditing]);

  const handleAdd = useCallback(() => {
    if (categories.find((category) => category.name === inputValue.trim())) {
      setIsInvalid(true);
    } else {
      setCategories([
        {id: undefined, name: inputValue.trim(), count: 0} as unknown as NewsCategoryWithCount,
        ...categories,
      ]);
    }
    setInputValue('');
  }, [categories, inputValue, setIsInvalid]);

  const handleClose = () => {
    close();
    setCategories(
      possibleCategories.map((possibleCategory) => ({
        id: possibleCategory.id,
        name: possibleCategory.name,
        count: possibleCategory.count,
      }))
    );
    setInputValue('');
    setIsEditing('');
    setIsInvalid(false);
  };

  const anyEdits = () => {
    const previous = possibleCategories.map((possibleCategory) => possibleCategory.name);
    const current = categories.map((category) => category.name);
    return (
      previous.length === current.length &&
      previous.every((value, index) => value === current[index])
    );
  };

  useEffect(() => {
    if (inputValue.trim() === '') {
      setInputValue(inputValue.trim());
    } else {
      setIsInvalid(false);
    }
  }, [inputValue, setInputValue]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && inputValue !== '') {
        if (isEditing) {
          handleEdit();
        } else {
          handleAdd();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleEdit, handleAdd, inputValue, isEditing]);

  return (
    <Modal open={open} withCloseButton="close" onRequestClose={handleClose}>
      <Modal.Header>
        <Title>{t('manage_categories')}</Title>
      </Modal.Header>
      <Modal.Body style={{overflowY: 'auto', paddingTop: '0px'}}>
        <div className={styles.addCategory}>
          <div style={{paddingBottom: '8px'}}>
            <Box as={Text} isBold size="large">
              {isEditing ? t('edit_category') : t('add_category')}
            </Box>
          </div>
          <TextInput
            size="large"
            placeholder={t('add_category_name')}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            withRightAccessory={
              inputValue &&
              (isEditing ? (
                <>
                  <IconButton
                    style={{marginTop: '8px'}}
                    variant="transparent"
                    onClick={() => {
                      setInputValue('');
                      setIsEditing('');
                    }}
                  >
                    <UIIcon src={CloseLine} />
                  </IconButton>
                  <IconButton style={{marginTop: '8px'}} variant="transparent" onClick={handleEdit}>
                    <UIIcon src={CheckmarkLine} />
                  </IconButton>
                </>
              ) : (
                <IconButton style={{marginTop: '8px'}} variant="transparent" onClick={handleAdd}>
                  <UIIcon src={AddLine} />
                </IconButton>
              ))
            }
            isInvalid={isInvalid}
            aria-errormessage="errmsg"
          />
          {isInvalid && (
            <FormHelperText variant="alert">
              <UIIcon src={FailLine} size="small" />
              <span id="errmsg">{t('category_exists')}</span>
            </FormHelperText>
          )}
        </div>
        <Box>
          <List spacing="small">
            <Box as={Eyebrow} color="Text Subtle" size="xsmall">
              {t('categories')}
            </Box>
            {categories.map((category) => (
              <CategoryItem
                key={category.name}
                category={category}
                handleDelete={handleDelete}
                setInputValue={setInputValue}
                setIsEditing={setIsEditing}
              />
            ))}
          </List>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
            handleCategoryChanges();
          }}
          disabled={anyEdits()}
        >
          {t('save_changes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CategoryItem = ({
  category,
  handleDelete,
  setInputValue,
  setIsEditing,
}: {
  category: NewsCategoryWithCount;
  handleDelete: (category: string) => void;
  setInputValue: (category: string) => void;
  setIsEditing: (isEditing: string) => void;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <List.Item onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <List.Content>
        <LabelGroup withText={category.name} />
      </List.Content>
      <List.Accessory>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '32px',
          }}
        >
          {hover ? (
            <>
              <IconButton variant="transparent" onClick={() => handleDelete(category.name)}>
                <UIIcon src={DeleteLine} />
              </IconButton>
              <IconButton
                variant="transparent"
                onClick={() => {
                  setInputValue(category.name);
                  setIsEditing(category.name);
                }}
              >
                <UIIcon src={EditLine} />
              </IconButton>
            </>
          ) : (
            <Text color="faint">{`${category.count} post${category.count !== 1 ? 's' : ''}`}</Text>
          )}
        </div>
      </List.Accessory>
    </List.Item>
  );
};

const DropZoneLabel = () => (
  <div className={styles.dropzoneText}>
    {t('new_post_dropzone')}
    <div>{t('new_post_dropzone_subtitle')}</div>
  </div>
);

export const NewsModifyThumbnail = ({
  id,
  selectedFile,
  setSelectedFile,
  onLoaded,
}: {
  id: number;
  selectedFile: File | null | undefined;
  setSelectedFile: (file: File | null) => void;
  onLoaded?: (hasThumbnail: boolean) => void;
}) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [hasThumbnail, setHasThumbnail] = useState<boolean>(true);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setImageSrc(reader.result);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile, setImageSrc]);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
    analyticsLogger().logEvent('NEWS_THUMBNAIL_UPLOADED', {type: 'button'});
  };

  const handleUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      setSelectedFile(e.dataTransfer.files[0]);
    }
    analyticsLogger().logEvent('NEWS_THUMBNAIL_UPLOADED', {type: 'dropzone'});
  };

  const onThumnailLoaded = (loaded: boolean) => {
    setHasThumbnail(loaded);
    onLoaded?.(loaded);
  };

  return (
    <FormRow>
      {selectedFile || hasThumbnail ? (
        <>
          <FormLabel className={styles.formTitle}>{t('thumbnail')}</FormLabel>
          <Box
            as={LabelGroup}
            size="small"
            withText={
              <div className={styles.uploadRemoveButtons}>
                <Button variant="opacity" size="small" onClick={handleUploadClick}>
                  {t('upload')}
                </Button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleUploadChange}
                  style={{display: 'none'}}
                />
                <Button
                  variant="outline"
                  size="small"
                  onClick={() => {
                    setSelectedFile(null);
                    setHasThumbnail(false);
                  }}
                >
                  {t('remove')}
                </Button>
              </div>
            }
            withSubtext={t('new_post_dropzone_subtitle')}
            withLeftAccessory={<NewsThumbnail id={id} src={imageSrc} onLoaded={onThumnailLoaded} />}
          />
        </>
      ) : (
        <>
          <FormLabel className={styles.formTitle}>{t('thumbnail')}</FormLabel>
          <Dropzone
            onDrop={handleDrop}
            label={<DropZoneLabel />}
            errorLabel={'Upload failed. Please try again'}
            uploadProgressLabel={'Uploading files...'}
            ariaLabel={'Select files to upload'}
            withCTA={
              <>
                <Button
                  variant="opacity"
                  onClick={handleUploadClick}
                  withIconStart={<UIIcon src={UploadLine} />}
                >
                  {t('upload')}
                </Button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleUploadChange}
                  style={{display: 'none'}}
                />
              </>
            }
          />
        </>
      )}
    </FormRow>
  );
};

export const NewsContentEditor = ({
  setValue,
  value,
  drawerIsOpen,
  setDrawerIsOpen,
  isEditing,
  actionButtons,
}: {
  setValue: (state: EditorState) => void;
  value?: string;
  drawerIsOpen: boolean;
  setDrawerIsOpen: (isOpen: boolean) => void;
  isEditing?: boolean;
  actionButtons?: boolean;
}) => (
  <FormRow>
    <Split>
      <Split.Item width="fill">
        <FormLabel>{t('content')}</FormLabel>
      </Split.Item>
      {(isEditing || actionButtons) && (
        <Split.Item>
          <Button variant="transparent" size="small" onClick={() => setDrawerIsOpen(!drawerIsOpen)}>
            {t('view_preview')}
          </Button>
        </Split.Item>
      )}
    </Split>
    <RichTextArea
      editable
      mentions
      media
      theme="news"
      source="news-editor"
      placeholder={t('start_writing_placeholder')}
      value={value}
      onChange={(state) => setValue(state)}
      autoFocus={false}
    />
  </FormRow>
);

export const PermissionsRow = ({
  audience,
  setNewsModify,
}: {
  audience: string;
  setNewsModify: (audience: string) => void;
}) => (
  <FormRow>
    <FormLabel
      className={styles.formTitle}
      withInput={
        <Select
          required
          id="category"
          size="large"
          onChange={(val) => setNewsModify(val)}
          value={audience}
          className={styles.formTextArea}
        >
          <Select.Option value="Company">{t('audience_Company')}</Select.Option>
          <Select.Option value="Managers">{t('audience_Managers')}</Select.Option>
          <Select.Option value="FTE">{t('audience_FTE')}</Select.Option>
        </Select>
      }
    >
      {t('permissions')}
    </FormLabel>
  </FormRow>
);
