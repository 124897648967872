import {analyticsLogger} from 'analytics/analyticsLogger';
import {Team} from 'client';
import {useTeamGoals, useTeamsGoalsSuspense} from 'components/goals/hooks';
import {useTeamSuspense} from 'components/teams/hooks';
import {useEffect, useState} from 'react';
import {
  DASHBOARDS_MAX_NUM_GOALS_T0_FETCH,
  DASHBOARDS_MAX_NUM_GOALS_T0_SHOW,
  DASHBOARDS_MAX_NUM_USERS_T0_SHOW,
} from 'views/dashboards/constants';
import {NoFilterTable} from 'views/goals_v2/EmptyGoalsTable';
import {GoalsTable} from 'views/goals_v2/GoalsTable';
import {GoalsHeaderData, GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {
  filterTable,
  getEmptyFilter,
  getExpandedTableProps,
  hasTooManyGoals,
  isOnlyTeamTableSelected,
  MAX_NUM_GOALS_TO_SHOW,
  shouldIncludeSubteamMembers,
} from 'views/goals_v2/utils';
import {queryClient} from 'views/QueryClientWrapper';

/*
 * Table that loads employee goals for team members (based on `team`)
 */
export const TeamGoalsTableComponent = ({
  index,
  keyPrefix,
  team,
  filter = getEmptyFilter(),
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  columnConfigs,
  onColumnDrag,
  isGoalsV2DrawerOpen,
  onRowClick,
  onContributorsClick,
  onContinueGoalClick,
  hasScrolledToRow,
  setScrolledToRow,
  selectedGoalData,
  goalsHeaderData,
  setGoalsHeaderData,
  handlerAddFilterButtonClick,
}: GoalsTablePropsWithoutData & {
  index: number;
  keyPrefix: string;
  team: Team;
}) => {
  const [tableMaxGoalsLimit, setTableMaxGoalsLimit] = useState<number>(
    DASHBOARDS_MAX_NUM_GOALS_T0_SHOW
  );

  const teamSlug = team.slug ?? '';
  const includeSubteamMembers = shouldIncludeSubteamMembers(teamSlug, filter);
  const teamInfo = useTeamSuspense({
    slug: teamSlug,
    includeSubteamMembers,
  });
  const teamsSet = new Set([team, ...filter.teams]);
  const teams = Array.from(teamsSet);

  const initialShouldShowMore =
    (teamInfo?.countMembers ?? 0) > DASHBOARDS_MAX_NUM_USERS_T0_SHOW &&
    isOnlyTeamTableSelected(filter);
  const [shouldShowMore, setShouldShowMore] = useState<boolean>(initialShouldShowMore);
  const [shouldShowMoreLoadingState, setShouldShowMoreLoadingState] = useState<boolean>(false);
  const limit = initialShouldShowMore ? DASHBOARDS_MAX_NUM_GOALS_T0_SHOW : MAX_NUM_GOALS_TO_SHOW;
  const {isFetched, goalsMap: filterTeamGoalsMap} = useTeamsGoalsSuspense({
    teams,
    timeframe,
    limit,
    includeSubteamMembers,
  });
  const teamGoals = filterTeamGoalsMap[teamSlug] ?? [];
  const filteredTeamGoals = teamGoals.filter(filterTable(filter));

  // Used to prefetch next batch of goals for show more
  const showMoreLimit = tableMaxGoalsLimit + DASHBOARDS_MAX_NUM_GOALS_T0_FETCH;
  const {
    isFetched: isShowMoreFetched,
    isLoading: isShowMoreLoading,
    goals: teamGoalsForShowMore = [],
  } = useTeamGoals({
    team: teams[0],
    timeframe: timeframe,
    limit: showMoreLimit,
    includeSubteamMembers,
    enabled: !!teams.length && shouldShowMore,
  });

  const showMoreGoals = () => {
    // Populate current goals cache with next batch of goals
    queryClient.setQueryData(
      ['goals', team.slug, limit, timeframe, includeSubteamMembers],
      teamGoalsForShowMore
    );
    // Update max goals limit to fetch next batch of goals
    setTableMaxGoalsLimit(showMoreLimit);
    setShouldShowMoreLoadingState(false);
  };

  useEffect(() => {
    if (isShowMoreFetched) {
      if (shouldShowMoreLoadingState && teamGoals.length !== teamGoalsForShowMore.length) {
        showMoreGoals();
      }
      if (!shouldShowMoreLoadingState && teamGoals.length === teamGoalsForShowMore.length) {
        setShouldShowMore(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isShowMoreFetched,
    shouldShowMoreLoadingState,
    teamGoals.length,
    teamGoalsForShowMore.length,
  ]);

  const goalsHeaderDataString = JSON.stringify(goalsHeaderData);
  const filterString = JSON.stringify(filter);
  useEffect(() => {
    // After goals are fetched for team, store goals count for header
    if (isFetched && setGoalsHeaderData && teamSlug) {
      const goalsCountMap = goalsHeaderData?.goalsCountMap ?? {};
      const filteredGoalsCountMap = goalsHeaderData?.filteredGoalsCountMap ?? {};
      const teamsMap = goalsHeaderData?.teamsMap ?? {};
      goalsCountMap[teamSlug] = teamGoals.length;
      filteredGoalsCountMap[teamSlug] = filteredTeamGoals.length;
      teamsMap[teamSlug] = team;
      const newGoalsHeaderData: GoalsHeaderData = {
        ...goalsHeaderData,
        goalsCountMap,
        filteredGoalsCountMap,
        teamsMap,
        showManyGoalsCount: shouldShowMore,
        initialShouldShowMore,
      };
      setGoalsHeaderData(newGoalsHeaderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    goalsHeaderDataString,
    setGoalsHeaderData,
    filterString,
    timeframe,
    tableMaxGoalsLimit,
    shouldShowMore,
  ]);

  if (
    goalsHeaderData &&
    handlerAddFilterButtonClick &&
    hasTooManyGoals(goalsHeaderData, filter, undefined, initialShouldShowMore)
  ) {
    return index === 0 ? <NoFilterTable onButtonClick={handlerAddFilterButtonClick} /> : null;
  }

  return (
    <GoalsTable
      keyPrefix={keyPrefix}
      withTitle={team.name ?? ''}
      type="other"
      data={filteredTeamGoals}
      timeframe={timeframe}
      nextTimeframe={nextTimeframe}
      setNextTimeframe={setNextTimeframe}
      filter={filter}
      columnConfigs={columnConfigs}
      onColumnDrag={onColumnDrag}
      isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
      onRowClick={onRowClick}
      onContributorsClick={onContributorsClick}
      onContinueGoalClick={onContinueGoalClick}
      hasScrolledToRow={hasScrolledToRow}
      setScrolledToRow={setScrolledToRow}
      selectedGoalData={selectedGoalData}
      teamInfo={{teamSlug, timeframe}}
      shouldShowMore={shouldShowMore}
      isShowMoreLoading={shouldShowMoreLoadingState ? isShowMoreLoading : false}
      setShowMore={() => {
        analyticsLogger().logEvent('TEAM_GOAL_TABLE_SHOW_MORE_CLICKED');
        if (isShowMoreFetched) {
          if (teamGoals.length === teamGoalsForShowMore.length) {
            setShouldShowMore(false);
          } else {
            showMoreGoals();
          }
        } else {
          setShouldShowMoreLoadingState(true);
        }
      }}
      {...getExpandedTableProps(true)}
    />
  );
};
