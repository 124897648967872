export const DASHBOARDS_DEFAULT_TIMEFRAME = 'Q3 FY24'; // TODO (mtruong): Remove hardcoded timeframe to switch to Q4

export const DASHBOARDS_LIGHT_STATUS_COLORS: {[key: string]: string} = {
  no_status: '#FFFFFF',
  on_track: '#DFF29F',
  at_risk: '#FBE8AE',
  off_track: '#FB9D83',
  complete: '#B4DF1C',
  missed: '#F77751',
  cancelled: '#D3CEC9',
};

export const DASHBOARDS_DARK_STATUS_COLORS: {[key: string]: string} = {
  no_status: '#1A1918',
  on_track: '#124B38',
  at_risk: '#4C3102',
  off_track: '#FB9D83',
  complete: '#0C392A',
  missed: '#85072F',
  cancelled: '#44403D',
};

export const DASHBOARDS_DARK_STATUS_TEXT_COLORS: {[key: string]: string | undefined} = {
  no_status: undefined,
  on_track: undefined,
  at_risk: undefined,
  off_track: undefined,
  complete: undefined,
  missed: undefined,
  cancelled: 'var(--dig-color__selection__on-base)',
};

export const DASHBOARD_STATUSES = Object.keys(DASHBOARDS_LIGHT_STATUS_COLORS);

export const DASHBOARDS_DROPBOX_NAME = 'Dropbox';
export const DASHBOARDS_DROPBOX_TEAM_SLUG = 'dropbox-teams';

export const DASHBOARDS_MAX_NUM_USERS_T0_SHOW = 50;
export const DASHBOARDS_MAX_NUM_GOALS_T0_SHOW = 50;
export const DASHBOARDS_MAX_NUM_GOALS_T0_FETCH = 100;
