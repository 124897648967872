import {Goal, GoalRead, GoalUser, KeyResult, Update} from 'client';
import {StatusButton} from 'components/DSYS/StatusButton';
import {t} from 'i18next';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {StatusModal} from 'views/goals_v2/StatusModal';
import {TeamInfo} from 'views/goals_v2/types';
import {isClosedStatus} from 'views/goals_v2/utils';

export const getSubstatus = (goal: Goal | GoalRead, latestUpdate?: Update) => {
  if (isClosedStatus(latestUpdate?.status, true)) {
    return undefined;
  }
  return goal.key_results.some((keyResult: KeyResult) => !keyResult.updates.length)
    ? 'no_status'
    : undefined;
};

export interface StatusButtonProps {
  title: string;
  latestUpdate: Update;
  substatus?: string;
  keyResultId?: number;
  goalId: number;
  timeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  user?: GoalUser;
  canEdit: boolean;
  isLabel?: boolean;
  isDisabled?: boolean;
  isDraft?: boolean | null;
  teamInfo?: TeamInfo;
  parentGoalUser?: GoalUser;
  ownerLdap?: string;
  disableAction?: boolean;
  onSuccess?: () => void;
  onContinueGoalClick?: (selectedStatus: string) => void;
}

/**
 * This is a wrapper around a DSYS/StatusButton
 *
 * It adds a modal to the button that allows the user to change the status of the goal
 */
export const GoalStatusButton = ({
  title,
  latestUpdate,
  goalId,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  user,
  keyResultId,
  substatus,
  canEdit,
  isLabel,
  isDisabled,
  isDraft,
  teamInfo,
  parentGoalUser,
  ownerLdap,
  disableAction = false,
  onSuccess,
  onContinueGoalClick,
}: StatusButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalState, setModalState] = useState({open: false, action: ''});
  // optional props for auto update text so that once it's reopened, it won't re-generate the AI text.
  const [updateCount, setUpdateCount] = useState(0);
  const [suggestText, setSuggestText] = useState('');

  const showModal = () => {
    setModalState({open: true, action: ''});
  };

  const navigateToGoalDetails = () => {
    if (goalId && !disableAction) {
      navigate(`/goals/${goalId}`, {state: {from: location.pathname}});
    }
  };

  return (
    <div>
      <StatusButton
        onClick={canEdit && !isDraft ? showModal : navigateToGoalDetails}
        status={isDraft ? 'draft' : latestUpdate?.status}
        substatus={isDraft ? undefined : substatus}
        isLabel={isLabel}
        disabled={isDisabled}
        autoUpdateText={
          canEdit && latestUpdate?.comment === 'automatic_objective_status_change'
            ? t('automatic_objective_status_change')
            : undefined
        }
      />
      {modalState.open && user && (
        <StatusModal
          title={title}
          user={user}
          state={modalState}
          setState={setModalState}
          goalId={goalId}
          timeframe={timeframe}
          nextTimeframe={nextTimeframe}
          setNextTimeframe={setNextTimeframe}
          keyResultId={keyResultId}
          previousUpdate={latestUpdate}
          teamInfo={teamInfo}
          parentGoalUser={parentGoalUser}
          ownerEmployeeLdap={ownerLdap}
          onSuccess={onSuccess}
          onContinueGoalClick={onContinueGoalClick}
          updateCount={updateCount}
          setUpdateCount={setUpdateCount}
          suggestText={suggestText}
          setSuggestText={setSuggestText}
        />
      )}
    </div>
  );
};
