import {Skeleton} from '@dropbox/dig-components/skeleton';
import {Box, Stack} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import DropboxIcon from 'assets/DropboxIcon.svg';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {isMobileAtom} from 'atoms/layout';
import {Layout} from 'components/DSYS/Layout';
import {
  Team,
  TeamMember,
  TeamsTreeItem,
  useTeamsCountSuspense,
  useTeamsTreeSuspense,
  useTeamSuspense,
} from 'components/teams/hooks';
import {DashboardsGoalCounts, useDashboardsGoalCounts} from 'hooks/useDashboards';
import {useAtomValue} from 'jotai';
import {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  DASHBOARDS_DEFAULT_TIMEFRAME,
  DASHBOARDS_DROPBOX_NAME,
  DASHBOARDS_DROPBOX_TEAM_SLUG,
} from 'views/dashboards/constants';
import {DashboardsBreadcrumbs} from 'views/dashboards/DashboardsBreadcrumbs';
import {DashboardsGoalsOverview} from 'views/dashboards/DashboardsGoalsOverview';
import {DashboardsHeatmap} from 'views/dashboards/DashboardsHeatmap';
import {DashboardsTeamNav} from 'views/dashboards/DashboardsTeamNav';
import {DashboardsTimeframeSelector} from 'views/dashboards/DashboardsTimeframeSelector';
import {createTeamDashboardsPath, getDefaultHeatmapStatus} from 'views/dashboards/util';
import {DashboardsGoalsWidget} from 'views/dashboards/widgets/DashboardsGoalsWidget';
import {DashboardsParticipationWidget} from 'views/dashboards/widgets/DashboardsParticipationWidget';

interface DashboardsViewProps {
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  navigateToTeamDashboard: (teamSlug: string) => void;
  employeeTeamSlug?: string;
}

export const DashboardsView = () => {
  const params = useParams();
  const {employeeTeams} = useAtomValue(loggedInEmployeeAtom);
  const employeeTeamSlug = employeeTeams?.length ? employeeTeams[0].slug ?? undefined : undefined;
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);
  const timeframeFromUrl = urlParams.get('t');
  const teamSlugFromUrl = params.teamSlug;
  const heatmapStatusFromUrl = urlParams.get('status');

  const defaultTimeframe = timeframeFromUrl ?? DASHBOARDS_DEFAULT_TIMEFRAME;
  const [timeframe, setTimeframe] = useState<string>(defaultTimeframe);
  const [selectedTeamSlug, setSelectedTeamSlug] = useState<string | undefined>(undefined);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    heatmapStatusFromUrl ?? getDefaultHeatmapStatus(defaultTimeframe)
  );

  useEffect(() => {
    // Default to employee team dashboard and
    // update dashboards view whenever team in URL changes
    const newTeamSlug = teamSlugFromUrl ?? employeeTeamSlug;
    if (newTeamSlug && newTeamSlug !== selectedTeamSlug) {
      setSelectedTeamSlug(newTeamSlug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSlugFromUrl, employeeTeamSlug]);

  useEffect(() => {
    // Update quarter selector whenever timeframe in URL changes
    if (timeframeFromUrl && timeframeFromUrl !== timeframe) {
      setTimeframe(timeframeFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframeFromUrl]);

  useEffect(() => {
    // Update status selector for heatmap whenever URL changes
    if (heatmapStatusFromUrl && heatmapStatusFromUrl !== selectedStatus) {
      setSelectedStatus(heatmapStatusFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatmapStatusFromUrl]);

  const handleSetTimeframe = useCallback(
    (newTimeframe: string) => {
      analyticsLogger().logEvent('DASHBOARD_TIMEFRAME');

      setTimeframe(newTimeframe);
      setSelectedStatus(getDefaultHeatmapStatus(newTimeframe));

      const newUrlParams = new URLSearchParams(location.search);
      newUrlParams.set('t', newTimeframe);
      newUrlParams.delete('status');
      navigate(
        {pathname: location.pathname, search: newUrlParams.toString()},
        {preventScrollReset: true}
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTimeframe]
  );

  const handleSetSelectedStatus = useCallback(
    (status: string) => {
      setSelectedStatus(status);
      const newUrlParams = new URLSearchParams(location.search);
      newUrlParams.set('status', status);
      navigate(
        {pathname: location.pathname, search: newUrlParams.toString()},
        {preventScrollReset: true}
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedStatus]
  );

  const handleNavigateToTeamDashboard = useCallback(
    (teamSlug: string) => {
      navigate(createTeamDashboardsPath(teamSlug, timeframe));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeframe]
  );

  const dashboardsViewProps: DashboardsViewProps = {
    timeframe,
    setTimeframe: handleSetTimeframe,
    selectedStatus,
    setSelectedStatus: handleSetSelectedStatus,
    navigateToTeamDashboard: handleNavigateToTeamDashboard,
    employeeTeamSlug,
  };

  return (
    <Layout.Container>
      <Suspense
        fallback={
          <Stack gap="24">
            <Skeleton.Box height={20} width={150} />
            <Skeleton.Box height={55} />
            <Skeleton.Box height={100} />
            <Skeleton.Box height={200} />
          </Stack>
        }
      >
        {selectedTeamSlug ? (
          selectedTeamSlug === DASHBOARDS_DROPBOX_TEAM_SLUG ? (
            <DropboxDashboardsView {...dashboardsViewProps} />
          ) : (
            <TeamDashboardsView {...dashboardsViewProps} teamSlug={selectedTeamSlug} />
          )
        ) : (
          <DropboxDashboardsView {...dashboardsViewProps} />
        )}
      </Suspense>
    </Layout.Container>
  );
};

export const DropboxDashboardsView = (props: DashboardsViewProps) => {
  const {timeframe} = props;
  const subTeams = useTeamsTreeSuspense();
  const {employees: membersCount} = useTeamsCountSuspense();
  const dashboardsGoalCounts = useDashboardsGoalCounts({
    teamSlug: DASHBOARDS_DROPBOX_TEAM_SLUG,
    timeframe,
    includeMembersCounts: false,
  });

  return (
    <DashboardsViewComponent
      {...props}
      teamName={DASHBOARDS_DROPBOX_NAME}
      teamSlug={DASHBOARDS_DROPBOX_TEAM_SLUG}
      teamNavIcon={<img src={DropboxIcon} width="56" />}
      subTeams={subTeams}
      membersCount={membersCount}
      dashboardsGoalCounts={dashboardsGoalCounts}
    />
  );
};

export const TeamDashboardsView = (
  props: DashboardsViewProps & {
    teamSlug: string;
  }
) => {
  const {teamSlug, timeframe} = props;
  const navigate = useNavigate();
  const team = useTeamSuspense({slug: teamSlug, includeHierarchy: true});
  const goalCounts = useDashboardsGoalCounts({
    teamSlug: teamSlug,
    timeframe,
    includeMembersCounts: true,
  });

  if (!team) {
    navigate('/404');
    return null;
  }

  const subTeams = team.subTeams || [];
  const formattedSubTeams: TeamsTreeItem[] = subTeams.map(
    ({name, teamId, slug, dbxTeamId}: Team) => ({
      teamName: name,
      teamId,
      slug,
      dbxTeamId: String(dbxTeamId),
    })
  );
  const members = team.members || [];

  return (
    <DashboardsViewComponent
      {...props}
      teamName={team.name}
      teamSlug={teamSlug}
      subTeams={formattedSubTeams}
      members={members}
      membersCount={subTeams.length ? team.countMembers : undefined}
      teamHierarchy={team.hierarchy}
      dashboardsGoalCounts={goalCounts}
    />
  );
};

export const DashboardsViewComponent = ({
  timeframe,
  setTimeframe,
  selectedStatus,
  setSelectedStatus,
  navigateToTeamDashboard,
  teamName,
  teamSlug,
  subTeams,
  members,
  membersCount,
  teamHierarchy,
  teamNavIcon,
  dashboardsGoalCounts,
  employeeTeamSlug,
}: DashboardsViewProps & {
  teamName: string;
  teamSlug: string;
  subTeams: TeamsTreeItem[];
  members?: TeamMember[];
  membersCount?: number;
  teamHierarchy?: Team[];
  teamNavIcon?: React.JSX.Element;
  dashboardsGoalCounts?: DashboardsGoalCounts;
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  const heatmapStatusSelectorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Box marginBottom="16">
        <DashboardsBreadcrumbs timeframe={timeframe} teamHierarchy={teamHierarchy} />
      </Box>
      <Box
        display={isMobile ? 'block' : 'flex'}
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        backgroundColor="Background Base"
        paddingTop="16"
        paddingBottom="16"
        style={{top: 52, zIndex: 2}}
      >
        <DashboardsTeamNav
          teamName={teamName}
          teamSlug={teamSlug}
          icon={teamNavIcon}
          numMembers={membersCount ?? members?.length ?? 0}
          numSubTeams={subTeams.length}
          navigateToTeamDashboard={navigateToTeamDashboard}
          employeeTeamSlug={employeeTeamSlug}
        />
        <DashboardsTimeframeSelector timeframe={timeframe} setTimeframe={setTimeframe} />
      </Box>
      <DashboardsGoalsOverview
        heatmapStatusSelectorRef={heatmapStatusSelectorRef}
        timeframe={timeframe}
        setSelectedStatus={setSelectedStatus}
        dashboardsGoalCounts={dashboardsGoalCounts}
      />
      <DashboardsHeatmap
        heatmapStatusSelectorRef={heatmapStatusSelectorRef}
        timeframe={timeframe}
        teamName={teamName}
        teamSlug={teamSlug}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        subTeams={subTeams}
        members={members}
        dashboardsGoalCounts={dashboardsGoalCounts}
      />
      <DashboardsParticipationWidget
        teamName={teamName}
        teamSlug={teamSlug}
        timeframe={timeframe}
      />
      <DashboardsGoalsWidget
        teamName={teamName}
        teamSlug={teamSlug}
        timeframe={timeframe}
        dashboardsGoalCounts={dashboardsGoalCounts}
      />
    </>
  );
};
