import {analyticsLogger} from 'analytics/analyticsLogger';
import {
  BreadcrumbData,
  BreadcrumbsComponent,
  TeamBreadcrumbs,
} from 'components/shared/TeamBreadcrumbs';
import {Team} from 'components/teams/hooks';
import {t} from 'i18next';
import {DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';
import {createDashboardsPath, createTeamDashboardsPath} from 'views/dashboards/util';

interface DashboardsBreadcrumbsProps {
  timeframe: string;
  teamHierarchy?: Team[];
  teamSlug?: string;
}

const formatDashboardsBreadcrumbs = (
  teamHierarchy: Team[],
  timeframe: string
): BreadcrumbData[] => {
  return teamHierarchy.map((team) => ({
    name: team.name,
    path: createTeamDashboardsPath(team.slug, timeframe),
  }));
};

export const DashboardsBreadcrumbs = ({
  timeframe,
  teamHierarchy,
  teamSlug,
}: DashboardsBreadcrumbsProps) => {
  const prefixBreadcrumbs = [
    {name: t('dashboards'), path: createDashboardsPath(timeframe), isBold: true},
    {name: 'Dropbox', path: createTeamDashboardsPath(DASHBOARDS_DROPBOX_TEAM_SLUG, timeframe)},
  ];

  const onClick = (path: string) => {
    analyticsLogger().logEvent('DASHBOARD_BREADCRUMB_CLICKED', {path});
  };

  if (!teamHierarchy?.length) {
    return teamSlug ? (
      <TeamBreadcrumbs
        teamSlug={teamSlug}
        formatDashboardsBreadcrumbs={(hierarchy: Team[]) =>
          formatDashboardsBreadcrumbs(hierarchy, timeframe)
        }
        prefixBreadcrumbs={prefixBreadcrumbs}
        onClick={onClick}
      />
    ) : (
      <BreadcrumbsComponent breadcrumbs={prefixBreadcrumbs} onClick={onClick} />
    );
  }

  const breadcrumbs = formatDashboardsBreadcrumbs(teamHierarchy, timeframe);
  return (
    <BreadcrumbsComponent
      breadcrumbs={breadcrumbs}
      prefixBreadcrumbs={prefixBreadcrumbs}
      onClick={onClick}
    />
  );
};
