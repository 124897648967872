import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/typography';
import {Box, useTheme} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TargetLine, TeamLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {TeamMember, TeamsTreeItem} from 'components/teams/hooks';
import {STEAM_TEAM_SLUG} from 'constant';
import {sortEmployees} from 'helpers/utils';
import {DashboardsGoalCounts} from 'hooks/useDashboards';
import {useEmployees} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useCallback, useState} from 'react';
import {To, useNavigate} from 'react-router-dom';
import {DASHBOARDS_DARK_STATUS_TEXT_COLORS} from 'views/dashboards/constants';
import {DashboardsActionMenu} from 'views/dashboards/DashboardsActionMenu';
import {DashboardsChartContainer} from 'views/dashboards/DashboardsChartContainer';
import {DashboardsGoalsOverviewGraph} from 'views/dashboards/DashboardsGoalsOverviewGraph';
import {DashboardsStatusSelectorWithInfoMessage} from 'views/dashboards/DashboardsStatusSelector';
import {
  createPeopleGoalsPath,
  createProfilePath,
  createTeamDashboardsPath,
  createTeamGoalsPath,
  createTeamsPath,
  getHeatmapHighlight,
  getRgbColor,
  getStatusColorsConfig,
  getStatusGoalCountsData,
  GoalCountsData,
} from 'views/dashboards/util';

import styles from './Dashboards.module.css';

type HeatmapCellType = 'team' | 'member';

interface HeatmapCellData {
  teamSlug: string;
  id: string;
  type: HeatmapCellType;
  name: string;
  metric: number;
  hasGoals: boolean;
  cellPath: To;
  viewGoalsPath: To;
  viewProfilePath?: To;
  viewTeamPath?: To;
  user?: Employee;
  shouldResizeToTwoCells?: boolean;
  // TODO (mtruong): Remove demo code
  isTop25?: boolean;
  normalizedOpacity?: number;
}

interface DashboardsHeatmapProps {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  teamName: string;
  teamSlug: string;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  subTeams: TeamsTreeItem[];
  members?: TeamMember[];
  dashboardsGoalCounts?: DashboardsGoalCounts;
}

const DASHBOARDS_HEATMAP_CELL_MIN_HEIGHT = '112px';
const DASHBOARDS_HEATMAP_CELL_MAX_HEIGHT = '296px';

export const DashboardsHeatmap = (props: DashboardsHeatmapProps) => {
  const {subTeams, members} = props;
  if (subTeams.length || members?.length) {
    return <DashboardsTeamsHeatmap {...props} />;
  }
  return null;
};

export const DashboardsTeamsHeatmap = (
  props: DashboardsHeatmapProps & {
    selectedStatus: string;
    setSelectedStatus: (status: string) => void;
  }
) => {
  const {selectedStatus, teamSlug, subTeams, members = [], timeframe, dashboardsGoalCounts} = props;
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const {mode} = useTheme();

  const subteamsCounts = dashboardsGoalCounts?.subteams_counts;
  const membersCounts = dashboardsGoalCounts?.members_counts;
  const goalCounts = dashboardsGoalCounts ? {...subteamsCounts, ...membersCounts} : undefined;

  // Create team cells
  const sTeam = subTeams.find((subTeam) => subTeam.slug === STEAM_TEAM_SLUG);
  const sortedTeams = subTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
  // Order S-team first and resize it to 2 cells
  const filteredTeams = sTeam
    ? [sTeam, ...sortedTeams.filter((team) => team.slug !== STEAM_TEAM_SLUG)]
    : sortedTeams;
  const teamCells = filteredTeams.map((team) => {
    const goalCountsData = subteamsCounts
      ? getStatusGoalCountsData(selectedStatus, mode, subteamsCounts[team.slug])
      : undefined;
    return {
      id: team.slug,
      type: 'team' as HeatmapCellType,
      name: team.teamName,
      metric: goalCountsData?.totalCount ? goalCountsData.percentageCeil : -1,
      hasGoals: !!goalCountsData?.totalCount,
      cellPath: createTeamDashboardsPath(team.slug, timeframe),
      viewGoalsPath: createTeamGoalsPath(team.slug, selectedStatus, timeframe),
      viewTeamPath: createTeamsPath(team.slug),
      teamSlug: team.slug,
      shouldResizeToTwoCells: team.slug === STEAM_TEAM_SLUG,
    };
  });
  const numTeamCells = sTeam ? filteredTeams.length + 1 : filteredTeams.length;

  // Create member cells
  const sortedTeamMembers = useEmployees({
    ldaps: members.map((member) => member.ldap) ?? [],
  }).sort(sortEmployees);
  const memberCells = sortedTeamMembers.map((member) => {
    const goalCountsData = membersCounts
      ? getStatusGoalCountsData(selectedStatus, mode, membersCounts[member.ldap])
      : undefined;
    const memberGoalsPath = createPeopleGoalsPath(
      member.ldap,
      selectedStatus,
      timeframe,
      employee.ldap
    );
    return {
      id: member.ldap,
      type: 'member' as HeatmapCellType,
      name: member.name,
      metric: goalCountsData?.totalCount ? goalCountsData.percentageCeil : -1,
      hasGoals: !!goalCountsData?.totalCount,
      cellPath: memberGoalsPath,
      viewGoalsPath: memberGoalsPath,
      viewProfilePath: createProfilePath(member.ldap),
      teamSlug: teamSlug,
      user: member,
    };
  });

  // Merge team and member cells
  let cells = [...teamCells, ...memberCells].sort((a, b) => {
    // If `a` is the STEAM_TEAM_SLUG, move it to the front
    if (a.id === STEAM_TEAM_SLUG) {
      return -1; // Move `a` to the front
    }
    // If `b` is the STEAM_TEAM_SLUG, move `b` to the front
    if (b.id === STEAM_TEAM_SLUG) {
      return 1; // Move `b` to the front
    }
    if (!a.hasGoals) {
      return 1; // Move `a` to the back
    }
    if (!b.hasGoals) {
      return -1; // Move `b` to the back
    }
    // Keep the original order if both `a` and `b` have goals
    return 0;
  });

  // TODO (mtruong): Remove demo code
  if (getHeatmapHighlight() === 'sort') {
    cells.sort((a, b) => {
      // If `a` is the STEAM_TEAM_SLUG, move it to the front
      if (a.id === STEAM_TEAM_SLUG) {
        return -1; // Move `a` to the front
      }
      // If `b` is the STEAM_TEAM_SLUG, move `b` to the front
      if (b.id === STEAM_TEAM_SLUG) {
        return 1; // Move `b` to the front
      }
      return b.metric - a.metric;
    });
  }
  const topCount = Math.ceil(cells.length * 0.25);
  const sortedPercentages = cells.map((cell) => cell.metric).sort((a, b) => b - a);
  const topPercentages = sortedPercentages.slice(0, topCount).filter((metric) => metric > 0);
  const minPerc = Math.min(...sortedPercentages);
  const maxPerc = Math.max(...sortedPercentages);
  if (getHeatmapHighlight() === 'top25') {
    cells = cells.map((cell) => {
      if (topPercentages.includes(cell.metric)) {
        return {...cell, isTop25: true};
      }
      return cell;
    });
  } else if (getHeatmapHighlight() === 'normalized') {
    cells = cells.map((cell) => {
      let normalizedOpacity = (cell.metric - minPerc) / (maxPerc - minPerc);
      if (cell.metric === minPerc || cell.metric === maxPerc) {
        normalizedOpacity = cell.metric / 100;
      }
      return {...cell, normalizedOpacity};
    });
  }

  return (
    <DashboardsHeatmapComponent
      {...props}
      cells={cells}
      numCells={numTeamCells + memberCells.length}
      goalCounts={goalCounts}
      totalGoalCounts={dashboardsGoalCounts?.total_counts}
      isSubteamsCountsLoading={dashboardsGoalCounts?.is_subteams_counts_loading}
    />
  );
};

export const DashboardsHeatmapComponent = ({
  heatmapStatusSelectorRef,
  timeframe,
  teamName,
  selectedStatus,
  setSelectedStatus,
  cells,
  numCells,
  teamSlug,
  goalCounts,
  totalGoalCounts,
  isSubteamsCountsLoading,
}: {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  teamName: string;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  cells: HeatmapCellData[];
  numCells: number;
  teamSlug?: string;
  goalCounts?: {[cellId: string]: {[status: string]: number}};
  totalGoalCounts?: {[status: string]: number};
  isSubteamsCountsLoading?: boolean;
}) => {
  const {mode} = useTheme();

  const numCols = window.innerWidth >= 1321 ? 4 : window.innerWidth >= 681 ? 2 : 1;
  const numRows = Math.ceil(numCells / numCols);
  const maxNumCells = numCols * numRows;
  const shouldRenderPlaceholderCells = numCells < maxNumCells && numCols > 1;

  const totalGoalCountsData = getStatusGoalCountsData(selectedStatus, mode, totalGoalCounts);

  return (
    <DashboardsChartContainer
      title={t('team_heatmap')}
      subtitle={t('team_heatmap_subtitle')}
      icon={TargetLine}
      style={{gridTemplateRows: `repeat(${numRows}, 1fr)`}}
    >
      <Box paddingBottom="16" marginBottom="16" borderColor="Border Subtle" borderBottom="Solid" />
      <DashboardsStatusSelectorWithInfoMessage
        type="heatmap"
        timeframe={timeframe}
        teamName={teamName}
        teamSlug={teamSlug ?? ''}
        statusSelectorRef={heatmapStatusSelectorRef}
        selectedStatus={selectedStatus}
        setSelectedStatus={(newStatus: string) => {
          analyticsLogger().logEvent('DASHBOARD_HEATMAP_CLICKED', {
            status: newStatus,
            event: 'status',
          });
          setSelectedStatus(newStatus);
        }}
        source="heatmap"
        totalGoalCountsData={totalGoalCountsData}
      />
      <div className={styles.dashboardsHeatmapList}>
        {cells.map((cellData) => {
          const goalCountsData = getStatusGoalCountsData(
            selectedStatus,
            mode,
            goalCounts?.[cellData.id]
          );
          return (
            <DashboardsHeatmapCell
              key={`dashboards-heatmap-cell__${cellData.id}`}
              timeframe={timeframe}
              cellData={cellData}
              numCols={numCols}
              numRows={numRows}
              goalCountsData={goalCountsData}
              isSubteamsCountsLoading={isSubteamsCountsLoading}
            />
          );
        })}
        {shouldRenderPlaceholderCells &&
          [...Array(Math.floor(maxNumCells - numCells))].map((_, index) => (
            <DashboardsHeatmapPlaceholderCell
              key={`dashboards-heatmap-placeholder-cell__${index}`}
            />
          ))}
      </div>
    </DashboardsChartContainer>
  );
};

export const DashboardsHeatmapCell = ({
  timeframe,
  cellData,
  numCols,
  numRows,
  goalCountsData,
  isSubteamsCountsLoading,
}: {
  timeframe: string;
  cellData: HeatmapCellData;
  numCols: number;
  numRows: number;
  goalCountsData?: GoalCountsData;
  isSubteamsCountsLoading?: boolean;
}) => {
  const {type, name, metric, cellPath, user, shouldResizeToTwoCells, isTop25, normalizedOpacity} =
    cellData;
  const navigate = useNavigate();
  const {mode} = useTheme();

  const cellStatusColor = isSubteamsCountsLoading ? undefined : goalCountsData?.cellStatusColor;
  const textColor =
    mode === 'dark' && cellStatusColor && goalCountsData?.status
      ? DASHBOARDS_DARK_STATUS_TEXT_COLORS[goalCountsData?.status]
      : undefined;
  const minHeight =
    numRows > 2 ? DASHBOARDS_HEATMAP_CELL_MIN_HEIGHT : DASHBOARDS_HEATMAP_CELL_MAX_HEIGHT;

  const onClick = useCallback(() => {
    analyticsLogger().logEvent('DASHBOARD_HEATMAP_CLICKED', {
      status: goalCountsData?.status,
      type,
      event: 'cell',
    });
    navigate(cellPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellPath]);

  const [isHovered, setHovered] = useState(false);

  const cssClasses = [styles.dashboardsHeatmapCell];
  if (numCols > 1 && shouldResizeToTwoCells) {
    cssClasses.push(styles.dashboardsHeatmapTwoCells);
  }

  // TODO (mtruong): Remove demo code
  const heatmapHighlight = getHeatmapHighlight();
  const highlightStatusColor =
    goalCountsData?.totalCount && goalCountsData?.status
      ? getStatusColorsConfig(mode)[goalCountsData.status]
      : undefined;
  const highlightStatusOpacity =
    heatmapHighlight === 'gradient' && metric > 0 ? metric / 100 : undefined;
  const backgroundColor =
    heatmapHighlight === 'gradient' && metric > 0 && highlightStatusColor
      ? getRgbColor(highlightStatusColor, highlightStatusOpacity)
      : heatmapHighlight === 'normalized' && metric > 0 && highlightStatusColor
      ? getRgbColor(highlightStatusColor, normalizedOpacity)
      : !isSubteamsCountsLoading && !goalCountsData?.totalCount
      ? mode === 'dark'
        ? 'var(--dig-color__background__subtle)'
        : 'var(--dig-color__disabled__on-base)'
      : isHovered
      ? undefined
      : heatmapHighlight === 'top25' && isTop25
      ? highlightStatusColor
      : heatmapHighlight === 'sort'
      ? undefined
      : cellStatusColor;

  return (
    <Box
      // Resize S-team to take up 2 cells
      className={cssClasses.join(' ')}
      style={{
        backgroundColor,
        minHeight,
        maxHeight: DASHBOARDS_HEATMAP_CELL_MAX_HEIGHT,
      }}
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth={isSubteamsCountsLoading || goalCountsData?.totalCount ? '1' : '0'}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isSubteamsCountsLoading ? (
        <Skeleton.Box height={16} width={50} />
      ) : (
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <LabelGroup
            align="top"
            withLeftAccessory={
              user ? (
                <Avatar user={user} size="small" />
              ) : (
                <UIIcon
                  src={TeamLine}
                  size="medium"
                  color={
                    backgroundColor
                      ? 'var(--dig-color__text__base)'
                      : 'var(--dig-color__text__subtle)'
                  }
                />
              )
            }
            withText={
              <Text size="small" style={{color: textColor}}>
                {name}
              </Text>
            }
          />
          <DashboardsHeatmapCellActionMenu cellData={cellData} isVisible={isHovered} />
        </Box>
      )}
      {goalCountsData && (
        <Box display="flex" flexDirection="column">
          {goalCountsData.totalCount ? (
            <>
              <Box style={{alignSelf: 'flex-end', marginLeft: 'auto'}}>
                <Text size="large" style={{color: textColor}} isBold>
                  {`${goalCountsData.percentageCeil}%`}
                </Text>
              </Box>
              <Box
                className={`${styles.dashboardsHeatmapCellGraph} ${
                  isHovered ? styles.dashboardsHeatmapCellGraphShiftUp : ''
                }`}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <DashboardsGoalsOverviewGraph
                  timeframe={timeframe}
                  height={12}
                  width="160px"
                  goalCounts={goalCountsData.statusCounts}
                  style={{position: 'relative'}}
                  showInfoOnHover
                />
                <Text size="small" color="subtle">
                  {t(goalCountsData.status)}
                </Text>
              </Box>
            </>
          ) : (
            <>
              {isHovered && (
                <Box style={{alignSelf: 'flex-end', marginLeft: 'auto'}}>
                  <Text size="large" style={{color: textColor}} isBold>
                    {`${goalCountsData.percentageCeil}%`}
                  </Text>
                </Box>
              )}
              <Box
                className={`${styles.dashboardsHeatmapCellGraph} ${
                  isHovered ? styles.dashboardsHeatmapCellGraphShiftUp : ''
                }`}
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
              >
                <Text size="small" color="subtle" style={{lineHeight: '15px'}}>
                  {t('no_goals')}
                </Text>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const DashboardsHeatmapCellActionMenu = ({
  cellData,
  isVisible,
}: {
  cellData: HeatmapCellData;
  isVisible: boolean;
}) => {
  const {viewGoalsPath, viewProfilePath, viewTeamPath} = cellData;
  return (
    <DashboardsActionMenu
      isVisible={isVisible}
      viewGoalsPath={viewGoalsPath}
      viewProfilePath={viewProfilePath}
      viewTeamPath={viewTeamPath}
    />
  );
};

export const DashboardsHeatmapPlaceholderCell = () => (
  <Box
    style={{
      opacity: 0.4,
      background: 'var(--Border-Subtle, rgba(0, 0, 0, 0.14))',
      minHeight: DASHBOARDS_HEATMAP_CELL_MIN_HEIGHT,
      maxHeight: DASHBOARDS_HEATMAP_CELL_MAX_HEIGHT,
    }}
    borderRadius="Medium"
  />
);
