import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {TextInput, TextInputInputRefObject} from '@dropbox/dig-components/dist/text_fields';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine, SearchLine} from '@dropbox/dig-icons/assets';
import {isMobileAtom} from 'atoms/layout';
import {useDebouncedValue} from 'hooks/useDebounce';
import {useAtomValue} from 'jotai';
import {useEffect, useRef, useState} from 'react';

interface TableSearchProps<T extends {search: string | null}> {
  placeholder: string;
  filter: T;
  setFilter: (filter: T) => void;
}

export const TableSearch = <T extends {search: string | null}>({
  placeholder,
  filter,
  setFilter,
}: TableSearchProps<T>) => {
  const isMobile = useAtomValue(isMobileAtom);
  const [showInput, setShowInput] = useState(false);
  const [search, setSearch] = useState(filter.search);
  const debouncedSearch = useDebouncedValue(search?.trim() || null, 300);
  const inputRef = useRef<TextInputInputRefObject>(null);

  useEffect(() => {
    if (debouncedSearch !== null) {
      setFilter({...filter, search: debouncedSearch.trim()});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(debouncedSearch)]);

  useEffect(() => {
    // Clear search bar whenever filter search is reset
    if (!filter.search?.length) {
      // setShowInput(false);
      setSearch('');
    }
  }, [filter.search]);

  const searchInput = (
    <Box paddingLeft="8" paddingRight="8" style={isMobile ? undefined : {minWidth: '228px'}}>
      <TextInput.Container>
        <TextInput.Accessory>
          <UIIcon src={SearchLine} />
        </TextInput.Accessory>
        <TextInput.Input
          autoFocus
          ref={inputRef}
          placeholder={placeholder}
          value={search ?? ''}
          onKeyDown={(e: any) => {
            if (e.key === 'Escape') {
              setShowInput(false);
              setSearch('');
            }
          }}
          onBlur={() => {
            if (search === null || search === '') {
              setShowInput(false);
            }
          }}
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
        />
        {!!search?.length && (
          <IconButton
            variant="borderless"
            size="small"
            onClick={() => {
              setSearch('');
              inputRef.current?.focus();
            }}
            style={{marginRight: -5}}
          >
            <UIIcon src={CloseLine}></UIIcon>
          </IconButton>
        )}
      </TextInput.Container>
    </Box>
  );

  return (
    <Box display="flex">
      {search?.length ? (
        searchInput
      ) : !showInput ? (
        <IconButton shape="circular" variant="transparent" onClick={() => setShowInput(true)}>
          <UIIcon src={SearchLine} />
        </IconButton>
      ) : (
        searchInput
      )}
    </Box>
  );
};
