/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {

    /**
     * Total Goal Counts Endpoint for Dashboards
     * @param teamSlug
     * @param timeframe
     * @returns any Successful Response
     * @throws ApiError
     */
    public static totalGoalCountsApiV1DashboardsTotalGoalCountsGet(
        teamSlug?: (string | null),
        timeframe?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboards/total_goal_counts',
            query: {
                'team_slug': teamSlug,
                'timeframe': timeframe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Subteam Goal Counts Endpoint for Dashboards
     * @param teamSlug
     * @param timeframe
     * @returns any Successful Response
     * @throws ApiError
     */
    public static subteamGoalCountsApiV1DashboardsSubteamGoalCountsGet(
        teamSlug?: (string | null),
        timeframe?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboards/subteam_goal_counts',
            query: {
                'team_slug': teamSlug,
                'timeframe': timeframe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Member Goal Counts Endpoint for Dashboards
     * @param teamSlug
     * @param timeframe
     * @returns any Successful Response
     * @throws ApiError
     */
    public static memberGoalCountsApiV1DashboardsMemberGoalCountsGet(
        teamSlug?: (string | null),
        timeframe?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboards/member_goal_counts',
            query: {
                'team_slug': teamSlug,
                'timeframe': timeframe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * People Widget Endpoint for Dashboards
     * @param type
     * @param teamSlug
     * @param timeframe
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public static peopleWidgetApiV1DashboardsPeopleWidgetGet(
        type: string,
        teamSlug?: (string | null),
        timeframe?: (string | null),
        limit?: (number | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboards/people_widget',
            query: {
                'type': type,
                'team_slug': teamSlug,
                'timeframe': timeframe,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Goals Widget Endpoint for Dashboards
     * @param type
     * @param teamSlug
     * @param timeframe
     * @param statuses
     * @returns any Successful Response
     * @throws ApiError
     */
    public static goalsWidgetApiV1DashboardsGoalsWidgetGet(
        type: string,
        teamSlug?: (string | null),
        timeframe?: (string | null),
        statuses?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dashboards/goals_widget',
            query: {
                'type': type,
                'team_slug': teamSlug,
                'timeframe': timeframe,
                'statuses': statuses,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
