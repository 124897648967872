/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Team } from '../models/Team';
import type { TeamWithCounts } from '../models/TeamWithCounts';
import type { TeamWithHierarchy } from '../models/TeamWithHierarchy';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {

    /**
     * Teams Endpoint
     * @returns TeamWithHierarchy Successful Response
     * @throws ApiError
     */
    public static getTeamsAllApiV1TeamsAllGet(): CancelablePromise<TeamWithHierarchy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/all',
        });
    }

    /**
     * Get Teams Area
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static getTeamsAreaApiV1TeamAreaGet(): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/team/area',
        });
    }

    /**
     * Teams Page Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTeamsApiV1TeamsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams',
        });
    }

    /**
     * Update Teams
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTeamsApiV1TeamsUpdateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/update',
        });
    }

    /**
     * Count Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCountApiV1CountGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/count',
        });
    }

    /**
     * Team Endpoint
     * @param slug
     * @param includeHierarchy
     * @param includeSubteamMembers
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTeamBySlugApiV1TeamsSlugSlugGet(
        slug: string,
        includeHierarchy?: (boolean | null),
        includeSubteamMembers?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/slug/{slug}',
            path: {
                'slug': slug,
            },
            query: {
                'include_hierarchy': includeHierarchy,
                'include_subteam_members': includeSubteamMembers,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Teams Endpoint
     * @param slugs
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static getTeamBySlugsApiV1TeamsSlugsSlugsGet(
        slugs: string,
    ): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/slugs/{slugs}',
            path: {
                'slugs': slugs,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Team Endpoint
     * @returns TeamWithCounts Successful Response
     * @throws ApiError
     */
    public static getHierarchyApiV1TeamsHierarchyGet(): CancelablePromise<Array<TeamWithCounts>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/hierarchy',
        });
    }

    /**
     * Team Endpoint
     * @param dbxTeamId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTeamApiV1TeamsDbxTeamIdGet(
        dbxTeamId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/{dbx_team_id}',
            path: {
                'dbx_team_id': dbxTeamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
