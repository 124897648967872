import {Button} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {PersonLine, PersonMultipleLine, TeamLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Pulse, PulseGrouping, PulseService, Team} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Title} from 'components/DSYS/Title';
import {Header} from 'components/shared/table/Header';
import {LabelGroupCell, LabelGroupSubtleCell} from 'components/shared/table/LabelGroupCell';
import {TableSearch} from 'components/shared/table/TableSearch';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {t} from 'i18next';
import {atom, useAtom, useAtomValue} from 'jotai';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import {MetricCell} from './MetricCell';
import {sortTable, toPulseGroup} from './utils';

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'name', width: 350},
    {type: 'esat', width: 70},
    {type: 'participation', short: 'par', width: 100},
  ];
};

export const withSearch = false;
export const enableTeamView = false;

type ViewType = 'subteam' | 'team' | 'manager';
const viewAtom = atom<ViewType>('subteam');

const PulseTableRow = ({
  metrics,
  onClick,
  responses_count,
  employee_count,
  ...rest
}: {
  onClick: (selected: string) => void;
} & PulseGrouping) => {
  const view = useAtomValue(viewAtom);
  const eSAT = metrics.find((metric) => metric.type === 'esat')?.metric ?? 0;
  const {employee, employeeTeams} = useAtomValue(loggedInEmployeeAtom);
  const grouping = toPulseGroup(rest, employee, employeeTeams);

  return (
    <Box
      key={grouping.id}
      as={Table.Row}
      onClick={() => responses_count && onClick(grouping.slug)}
      isSelectable={responses_count > 0}
    >
      <LabelGroupCell
        withLeftAccessory={grouping.image && <Avatar size="large" user={grouping} />}
        text={
          <Split alignY="center" gap="8">
            <Split.Item>
              <Text>{grouping.name}</Text>
            </Split.Item>
            {grouping.self && (
              <Split.Item>
                <Chip size="small" variant="attention">
                  <Text size="small">Your {view === 'manager' ? 'manager' : 'team'}</Text>
                </Chip>
              </Split.Item>
            )}
          </Split>
        }
        subText={t(view === 'manager' ? 'reports' : 'member', {
          count: employee_count,
          countString: employee_count.toLocaleString(),
        })}
      />
      <MetricCell value={eSAT} /*change={eNPSchange}*/ responses={responses_count} minimum={4} />
      <LabelGroupSubtleCell
        text={`${Math.round((responses_count / (employee_count || 1)) * 100)}%`}
        subText={`${responses_count} of ${employee_count}`}
      />
    </Box>
  );
};

export const PulseTable = ({
  pulse,
  columnConfigs,
  dragging,
  onColumnDrag,
}: {
  team?: Team;
  pulse: Pulse;
  pillar: string;
  columnConfigs: ColumnConfig[];
  dragging?: string;
  onColumnDrag: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState({type: 'name', asc: true});
  const [view, setView] = useAtom(viewAtom);
  const [filter, setFilter] = useState({search: ''});
  const tableWidth = 270 + columnConfigs.reduce((acc, {width}) => acc + width, 0);
  const {employee, employeeTeams} = useAtomValue(loggedInEmployeeAtom);
  const {data, isLoading} = useQuery({
    queryKey: ['pulse', 'table', view, pulse.id],
    queryFn: () => getService(PulseService).pulseTableGroupApiV1PulseGroupIdGet(view, pulse.id),
  });

  const preFilteredData = data?.filter(({employee_count}) => employee_count) ?? [];

  const sortedTable =
    preFilteredData
      ?.filter((grouping) =>
        toPulseGroup(grouping).name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase())
      )
      .sort(sortTable(sort, employee, employeeTeams)) ?? [];

  return (
    <Box
      as="div"
      paddingX="24"
      paddingY="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderWidth="1"
      maxWidth="100%"
      marginX="auto"
      style={{width: tableWidth, overflowX: 'auto'}}
    >
      <Box as={Split} gap="6" alignY="center" marginBottom="4">
        <Split.Item width="fill">
          {isLoading ? (
            <Skeleton.Box width={100} height={24} />
          ) : (
            <Title size={18} style={{width: 200}}>
              {sortedTable.length === preFilteredData.length
                ? t(view === 'manager' ? 'manager_count' : 'team_count', {
                    count: preFilteredData.length,
                  })
                : t(view === 'manager' ? 'manager_count_filter' : 'team_count_filter', {
                    filtered: sortedTable.length,
                    count: preFilteredData.length,
                  })}
            </Title>
          )}
        </Split.Item>
        <Split.Item width="hug">
          <Menu.Wrapper
            onSelection={(value) => setView(value)}
            shouldPropagateClickOutsideMouseEvents
          >
            {({getContentProps, getTriggerProps}) => (
              <>
                <Box
                  as={Button}
                  {...getTriggerProps()}
                  variant="borderless"
                  withDropdownIcon
                  withIconStart={<UIIcon src={view === 'subteam' ? TeamLine : PersonLine} />}
                >
                  {view === 'subteam' ? 'Team' : 'Manager'}
                </Box>
                <Menu.Content {...getContentProps()} minWidth="200px">
                  <Menu.Segment withLabel={t('view_by')}>
                    <Menu.ActionItem withLeftAccessory={<UIIcon src={TeamLine} />} value="subteam">
                      Team
                    </Menu.ActionItem>
                    <Menu.ActionItem
                      withLeftAccessory={<UIIcon src={PersonLine} />}
                      value="manager"
                    >
                      Manager
                    </Menu.ActionItem>
                    {enableTeamView && (
                      <Menu.ActionItem
                        withLeftAccessory={<UIIcon src={PersonMultipleLine} />}
                        value="team"
                      >
                        Team
                      </Menu.ActionItem>
                    )}
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        </Split.Item>
        {withSearch && (
          <>
            <Split.Item>
              <Box as="div" paddingY="12" borderLeft="Solid" borderColor="Border Subtle" />
            </Split.Item>
            <Split.Item>
              <TableSearch placeholder="Search..." filter={filter} setFilter={setFilter} />
            </Split.Item>
          </>
        )}
      </Box>

      <Table hasDividers={false} spacing="small" verticalAlign="center">
        <Header
          columnConfigs={columnConfigs}
          sort={sort}
          setSort={setSort}
          dragging={dragging}
          getMouseDownHandler={onColumnDrag}
        />
        <Table.Body>
          <Box as="div" style={{height: 8}} />
          {sortedTable.map((row) => (
            <PulseTableRow
              {...row}
              key={toPulseGroup(row).id}
              onClick={(selected) => navigate(`/pulse/${view}/${selected}/${pulse.id}`)}
            />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
