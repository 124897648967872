import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Slider} from '@dropbox/dig-components/dist/slider';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {FilterLine, SearchLine, TeamLine} from '@dropbox/dig-icons/assets';
import {isMobileAtom} from 'atoms/layout';
import {TeamWithHierarchy} from 'client';
import {Title} from 'components/DSYS/Title';
import {TeamConnectSearch} from 'components/goals/edit/TeamConnectSearch';
import {statusToColor} from 'components/projects/ProjectStatusButton';
import {useDebouncedValue} from 'hooks/useDebounce';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {ReactNode, useEffect, useMemo, useState} from 'react';
import {getEmptyFilter, ProjectTableFilter} from 'views/projects/utils';

type Filter = {type: keyof ProjectTableFilter; value: string | number | string[]};

const statusGroups = {
  Planning: [
    '💡 Backlog / Not Committed',
    'Ideation',
    '🥚 Not Started',
    '🛑 On hold',
    '🛑 Blocked',
  ],
  Product: [
    '✏️ Needs Investigation / Sizing ',
    '✏️ Investigating / Sizing in Progress',
    '🔎 Needs Research',
    '🔎 Research in Progress',
    '🏠 Needs Product Requirements',
    '🏠 Product Requirements in Progress',
    '🎨 Needs Design',
    '🎨 Design in Progress',
    '📊 Analysis In Progress',
  ],
  Engineering: [
    '⚒️ Needs Eng Spec',
    '⚒️ Eng Spec in Progress',
    '⚒️ Ready for Development',
    '⚒️ Building',
    '⚒️ Ongoing Work',
    '⚒️ Code Complete',
    '⚒️ QA',
    '⚒️ QA Complete',
    '⚒️ Demoable',
  ],
  Complete: ['⌛ Running', '✅ Done', "✅ GA'ed", '🚫 Deprioritized', '🚫 Cancelled', 'Ended'],
};

const FilterSection = ({
  options,
  section,
  progress,
  filter,
  handleSelection,
}: {
  section: string;
  progress?: number;
  options: {label?: string | ReactNode; option: Filter}[];
  filter: ProjectTableFilter;
  handleSelection?: (filter: Filter) => void;
}) => {
  const isSelected = (data: Filter) =>
    data.type === 'progress'
      ? filter[data.type] !== undefined
      : filter[data.type].includes(data.value.toString());

  return (
    <Box>
      {Boolean(options.length) && (
        <Box
          as={Text}
          isBold
          size="small"
          color="Text Subtle"
          paddingY="4"
          paddingX="16"
          display="block"
        >
          {section}
        </Box>
      )}
      {options?.[0]?.option.type === 'progress' ? (
        <>
          <Menu.Row
            withLeftAccessory={
              <Checkbox
                checked={isSelected(options[0].option)}
                onClick={() => handleSelection?.(options[0].option)}
              />
            }
          >
            <Text isBold={isSelected(options[0].option)}>{options[0].label}</Text>
          </Menu.Row>

          <Box
            as={Text}
            size="small"
            color="Text Subtle"
            paddingLeft="52"
            display="block"
            visibility={filter.progress !== undefined ? 'visible' : 'hidden'}
          >
            {`Below ${progress}%`}
          </Box>
        </>
      ) : options?.[0]?.option.type === 'team' ? (
        <Menu.Row>{options[0].label}</Menu.Row>
      ) : options?.[0]?.option.type === 'status' ? (
        <Box
          style={{
            display: 'grid',
            columnGap: 16,
            gridTemplateColumns: `repeat(2, 1fr)`,
          }}
        >
          {options.map(({label, option}) => (
            <Box
              as={Menu.ActionItem}
              borderRadius="Small"
              key={`${option.type}-${option.value}`}
              value={option}
              role="menuitemcheckbox"
              aria-checked={isSelected(option)}
              withLeftAccessory={<Checkbox checked={isSelected(option)} readOnly tabIndex={-1} />}
              withRightAccessory={
                option.type === 'availability' && (
                  <Text color="subtle" size="small">
                    {(option.value as string).split(' ')[0].replace(/FY/, '20')}
                  </Text>
                )
              }
            >
              <Box
                as={Text}
                paddingX="6"
                paddingY="4"
                borderRadius="Large"
                style={{backgroundColor: statusToColor(option.value as string)}}
                isBold={isSelected(option)}
              >
                {label ?? t(option.value.toString())}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        options.map(({label, option}) => (
          <Box
            as={Menu.ActionItem}
            borderRadius="Small"
            key={`${option.type}-${option.value}`}
            value={option}
            role="menuitemcheckbox"
            aria-checked={isSelected(option)}
            withLeftAccessory={<Checkbox checked={isSelected(option)} readOnly tabIndex={-1} />}
            withRightAccessory={
              option.type === 'availability' && (
                <Text color="subtle" size="small">
                  {(option.value as string).split(' ')[0].replace(/FY/, '20')}
                </Text>
              )
            }
          >
            <Text isBold={isSelected(option)}>{label ?? t(option.value.toString())}</Text>
          </Box>
        ))
      )}
    </Box>
  );
};

const FilterChips = ({
  filter,
  setFilter,
}: {
  filter: ProjectTableFilter;
  setFilter: (filter: ProjectTableFilter) => void;
}) => {
  const getLabel = (type: string, value: string) => {
    if (type === 'progress') {
      return `Progress below ${value}%`;
    }
    if (type === 'availability') {
      return `Available ${value.split(' ')[0].replace(/FY/, '20')}`;
    }
    return t(value);
  };

  return (
    <Split gap="4" alignX="right" style={{flexWrap: 'wrap'}}>
      {Object.entries(filter).map(([type, values]) => {
        return type === 'search' ? null : type === 'progress' ? (
          typeof values === 'number' ? (
            <Split.Item key={`chip-${type}-${values}`}>
              <Chip
                isSelected
                size="small"
                onDelete={() => setFilter({...filter, progress: undefined})}
              >
                <Chip.Content>{getLabel(type, values.toString())}</Chip.Content>
              </Chip>
            </Split.Item>
          ) : null
        ) : (
          (values as string[]).map((value) => {
            return (
              <Split.Item key={`chip-${type}-${value}`}>
                <Chip
                  isSelected
                  size="small"
                  onDelete={() => {
                    const toUpdate = filter[type as keyof ProjectTableFilter] as string[];
                    const index = toUpdate.indexOf(value);
                    toUpdate.splice(index, 1);

                    setFilter({...filter, [type]: toUpdate});
                  }}
                >
                  {/* {type === 'status' && (
                    <Chip.IconAccessory>
                      <StatusButtonIcon status={value} />
                    </Chip.IconAccessory>
                  )} */}
                  {type === 'team' && (
                    <Chip.IconAccessory>
                      <UIIcon src={TeamLine} />
                    </Chip.IconAccessory>
                  )}
                  <Chip.Content>{getLabel(type, value)}</Chip.Content>
                </Chip>
              </Split.Item>
            );
          })
        );
      })}
    </Split>
  );
};

const currentYear = parseInt(new Date().getFullYear().toString().slice(-2), 10);
const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);

const getFilterTypes = (
  allTeams: string[],
  teams: string[] | undefined,
  handleSetTeams: (team: string[]) => void,
  progress: number | undefined,
  handleSetProgress: (progress: number) => void
): {[key: string]: {label?: string | ReactNode; option: Filter}[]} => ({
  team: [
    {
      label: (
        <TeamConnectSearch teams={allTeams} teamIds={teams} handleTeamSelected={handleSetTeams} />
      ),
      option: {type: 'team', value: []},
    },
  ],
  availability: [
    {
      label: 'This quarter',
      option: {
        type: 'availability',
        value: `Q${currentQuarter} FY${currentYear}`,
      },
    },
    {
      label: 'Next quarter',
      option: {
        type: 'availability',
        value: `Q${currentQuarter + 1 > 4 ? 1 : currentQuarter + 1} FY${
          currentQuarter + 1 > 4 ? currentYear + 1 : currentYear
        }`,
      },
    },
    {
      label: 'This year',
      option: {type: 'availability', value: `FY${currentYear}`},
    },
    {
      label: 'Next year',
      option: {type: 'availability', value: `FY${currentYear + 1}`},
    },
  ],
  progress: [
    {
      label: (
        <Slider
          aria-label="ticket-progress"
          originPoint={0}
          value={progress}
          onChange={handleSetProgress}
          labelFn={() => undefined}
        />
      ),
      option: {type: 'progress', value: 0},
    },
  ],
  updated: [
    {
      option: {type: 'updated', value: 'filter_update_this'},
    },
    {
      option: {type: 'updated', value: 'filter_update_last'},
    },
    {
      option: {type: 'updated', value: 'filter_update_stale'},
    },
    {
      option: {type: 'updated', value: 'filter_update_new'},
    },
  ],
});

const flattenTeamHierarchy = (area: TeamWithHierarchy): string[] => {
  const teams: string[] = [];
  const traverse = (team: TeamWithHierarchy) => {
    if (!team.children || !team.children.length) {
      teams.push(team.name);
    } else {
      team.children.forEach((child) => {
        traverse(child);
      });
    }
  };
  traverse(area);
  return teams;
};

export const TableFilter = ({
  area,
  filter,
  setFilter,
}: {
  area: TeamWithHierarchy;
  filter: ProjectTableFilter;
  setFilter: (filter: ProjectTableFilter) => void;
}) => {
  const isMobile = useAtomValue(isMobileAtom);
  const [progress, setProgress] = useState<number | undefined>(filter.progress);
  const [inputValue, setInputValue] = useState('');
  const [statusViewMore, setStatusViewMore] = useState({
    Planning: false,
    Product: false,
    Engineering: false,
    Complete: false,
  });

  const allTeams = useMemo(() => flattenTeamHierarchy(area), [area]);

  const handleSelection = ({type, value}: Filter) => {
    if (type === 'progress') {
      setFilter({...filter, [type]: filter.progress !== undefined ? undefined : 100});
      setProgress(filter.progress !== undefined ? undefined : 100);
      return;
    }
    const toUpdate = filter[type] as string[];
    const index = toUpdate.indexOf(value as string);
    if (index === -1) {
      toUpdate.push(value as string);
    } else {
      toUpdate.splice(index, 1);
    }
    setFilter({...filter, [type]: toUpdate});
  };

  const debouncedProgress = useDebouncedValue(progress, 700);

  useEffect(() => {
    setFilter({...filter, progress: debouncedProgress});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProgress, setFilter]);

  const handleSetTeams = (team: string[]) => {
    setFilter({...filter, team});
  };

  const filterTypes = getFilterTypes(allTeams, filter.team, handleSetTeams, progress, setProgress);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Split alignY="center">
        <FilterChips filter={filter} setFilter={setFilter} />
        <Menu.Wrapper
          closeOnSelection={false}
          onSelection={handleSelection}
          isPortaled={false}
          onToggle={() =>
            setStatusViewMore({
              Planning: false,
              Product: false,
              Engineering: false,
              Complete: false,
            })
          }
        >
          {({getContentProps, getTriggerProps}) => (
            <>
              <Box
                as={IconButton}
                {...getTriggerProps()}
                shape="circular"
                variant="transparent"
                marginLeft="8"
              >
                <UIIcon src={FilterLine} />
              </Box>

              <Menu.Content {...getContentProps()} placement="bottom-end">
                <Box as={Stack} paddingTop="12" paddingBottom="24">
                  <Box
                    paddingX="16"
                    as={Title}
                    size={16}
                    withAccessoryEnd={
                      <Box
                        as={Button}
                        variant="borderless"
                        size="small"
                        color="Text Subtle"
                        onClick={() => setFilter(getEmptyFilter())}
                      >
                        Reset
                      </Box>
                    }
                  >
                    Filters
                  </Box>
                  <Box
                    as="div"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: isMobile ? 'repeat(1fr, 3)' : 'repeat(3, 1fr)',
                      gridGap: '8px',
                      minWidth: 1000,
                    }}
                  >
                    <Box as={Stack} gap="12" style={{flex: 1, minWidth: 276}}>
                      {allTeams.length > 1 && (
                        <FilterSection section="Team" options={filterTypes.team} filter={filter} />
                      )}

                      <FilterSection
                        section="Availability"
                        options={filterTypes.availability}
                        filter={filter}
                      />
                      <FilterSection
                        section="Progress"
                        options={filterTypes.progress}
                        progress={progress}
                        filter={filter}
                        handleSelection={handleSelection}
                      />
                      <FilterSection
                        section="Last updated"
                        options={filterTypes.updated}
                        filter={filter}
                      />
                    </Box>

                    <Box
                      borderLeft="Solid"
                      borderColor="Border Subtle"
                      borderWidth={isMobile ? '0' : '1'}
                      paddingLeft={isMobile ? '0' : '8'}
                      style={{gridColumn: 'span 2'}}
                    >
                      <Box as={Stack} gap="12">
                        <Box>
                          <Box
                            as={Text}
                            isBold
                            size="small"
                            color="Text Subtle"
                            paddingY="4"
                            paddingX="16"
                            display="block"
                          >
                            Status
                          </Box>
                          <Menu.Row>
                            <Box paddingRight="4">
                              <TextInput
                                size="large"
                                withLeftAccessory={
                                  <UIIcon src={SearchLine} className={atoms({marginTop: '8'})} />
                                }
                                placeholder={
                                  filter.status.length
                                    ? filter.status.length === 1
                                      ? '1 status selected'
                                      : `${filter.status.length} statuses selected`
                                    : t('select_status').toString()
                                }
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck={false}
                                value={inputValue}
                                // withRightAccessory={
                                //   <Box
                                //     as={UIIcon}
                                //     src={SearchLine}
                                //     color="Text Subtle"
                                //     marginTop="8"
                                //   />
                                // }
                                onChange={onInputChange}
                                onKeyDown={handleKeyDown}
                              />
                            </Box>
                          </Menu.Row>
                        </Box>
                        {Object.entries(statusGroups).map(([ke, va]) => (
                          <Box key={ke}>
                            <FilterSection
                              section={ke}
                              options={va
                                .filter((status) =>
                                  status
                                    .toLocaleLowerCase()
                                    .includes(inputValue.toLocaleLowerCase())
                                )
                                .slice(
                                  0,
                                  inputValue.length ||
                                    statusViewMore[ke as keyof typeof statusViewMore]
                                    ? va.length
                                    : 6
                                )
                                .map((status) => ({
                                  label: status,
                                  option: {type: 'status', value: status},
                                }))}
                              filter={filter}
                              handleSelection={handleSelection}
                            />
                            {va.filter((status) =>
                              status.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
                            ).length > 6 &&
                              !inputValue.length && (
                                <Box
                                  as={Button}
                                  marginLeft="16"
                                  marginY="4"
                                  size="small"
                                  variant="transparent"
                                  onClick={() =>
                                    setStatusViewMore({
                                      ...statusViewMore,
                                      [ke]: !statusViewMore[ke as keyof typeof statusViewMore],
                                    })
                                  }
                                >
                                  {!statusViewMore[ke as keyof typeof statusViewMore]
                                    ? 'View all'
                                    : 'View less'}
                                </Box>
                              )}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      </Split>
    </>
  );
};
