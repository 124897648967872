import {Chip} from '@dropbox/dig-components/dist/chip';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {pulseUserAtom} from 'atoms/auth';
import {newsPostIdAtom} from 'atoms/news';
import {NewsPostContent} from 'client';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';
import {NewsPostRightLoading} from 'components/news/NewsLoadingState';
import {isContentEditor} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtom, useAtomValue} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAddViewer, usePostSuspense} from './hooks';
import styles from './NewsPostRight.module.css';

export const NewsPostRight = (props: {id: number}) => {
  const [loadedNewsPostId, setLoadedNewsPostId] = useAtom(newsPostIdAtom);
  const toLoad = loadedNewsPostId ? loadedNewsPostId : props.id;
  const newspost = usePostSuspense(toLoad);
  const pulseUser = useAtomValue(pulseUserAtom);
  const isEditorView = pulseUser && isContentEditor(pulseUser.email);

  useEffect(() => {
    if (props.id !== loadedNewsPostId) {
      setLoadedNewsPostId(props.id);
    }
  }, [loadedNewsPostId, props.id, setLoadedNewsPostId]);

  useDocumentTitle(newspost.title);

  if (!pulseUser) {
    return <NewsPostRightLoading />;
  }

  if (newspost.is_draft && !isEditorView) {
    return <NotFound />;
  }

  return (
    <div className={styles.newsPostRight}>
      <Title className={styles.title} weightVariant="emphasized" size="large">
        {newspost.title}
      </Title>
      <Info newspost={newspost} isDraft={newspost.is_draft ?? false} />
      <RichTextArea
        value={newspost.body}
        theme="news"
        source={`news-perview-${loadedNewsPostId}`}
      />
    </div>
  );
};

const Info = ({newspost, isDraft}: {newspost: NewsPostContent; isDraft: boolean}) => {
  const navigate = useNavigate();

  if (isDraft) {
    return (
      <div className={styles.info}>
        <Chip size="small" className={styles.draftChip}>
          <Chip.Content>{t('draft')}</Chip.Content>
        </Chip>
        {newspost.category.map((category) => (
          <Chip
            key={category.name}
            size="small"
            onClick={() =>
              navigate('/news/' + category.name, {state: {source: 'news-category-chip'}})
            }
          >
            <Chip.Content>{category.name}</Chip.Content>
          </Chip>
        ))}
      </div>
    );
  }

  const date = new Date(newspost.created_at);
  const august29 = new Date('2024-08-29');
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  const formattedDate = date.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);

  return (
    <div className={styles.info}>
      {newspost.category.map((category) => (
        <Chip
          key={category.name}
          size="small"
          onClick={() =>
            navigate('/news/' + category.name.toLowerCase(), {
              state: {source: 'news-category-chip'},
            })
          }
        >
          <Chip.Content>{category.name}</Chip.Content>
        </Chip>
      ))}
      <Text color="subtle">{formattedDate}</Text>
      {date >= august29 && <ViewCountLabel id={newspost.id} isDraft={isDraft} />}
    </div>
  );
};

const ViewCountLabel = ({id, isDraft}: {id: number; isDraft: boolean}) => {
  const {addViewer, isPending} = useAddViewer();
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    (async () => {
      if (!isDraft) {
        setViewCount(await addViewer({id: id}));
      }
    })();
  }, [addViewer, isDraft, id, viewCount]);

  const viewLabel = `${viewCount} view${viewCount !== 1 ? 's' : ''}`;

  if (viewCount === 0) {
    return null;
  }

  return isPending ? (
    <Skeleton.Text width={48} />
  ) : (
    <>
      <Text color="subtle"> • </Text>
      <Text color="subtle">{viewLabel}</Text>
    </>
  );
};
